import { ref } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
import VideoItem from '../common/VideoItem.vue';
import SwipeComponent from "../common/SwipeComponent.vue";
export default {
  components: {
    VideoItem,
    SwipeComponent
  },

  setup(props) {
    const store = useStore();
    const {
      fansApi
    } = getGlobalProperties().$api;
    let videoList = ref([]);
    let page = ref(0);
    const error = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    videoList.value = localStorage.getItem("FocusComponent") ? JSON.parse(localStorage.getItem("FocusComponent")) : [];

    const getVideoList = () => {
      fansApi({
        page: page.value
      }, "get").then(res => {
        if (res.code === 0) {
          refreshing.value = false;

          if (page.value === 1) {
            localStorage.setItem("FocusComponent", JSON.stringify(res.data.rows.slice(0, 3)));
          }

          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const toAutorDetails = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const showShare = item => {
      store.commit("SET_SHOW_SHARE_POPUP", {
        show: true,
        videoDetails: item
      });
    };

    const onRefresh = () => {
      videoList.value = [];
      loading.value = true;
      page.value = 1;
      getVideoList();
    };

    return {
      props,
      videoList,
      loading,
      onLoad,
      error,
      videoPlay,
      finished,
      toAutorDetails,
      showShare,
      onRefresh,
      refreshing
    };
  }

};