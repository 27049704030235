import { ref, computed } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  setup(props) {
    const store = useStore();
    let dataItem = computed(() => {
      // 计算属性初始化加10
      return ref(props.data);
    });
    const {
      askVideoWantApi
    } = getGlobalProperties().$api;

    const getaskVideoWant = item => {
      // if (item.isWant) {
      //   return;
      // }
      const params = {
        ask_video_id: item.id
      };
      askVideoWantApi(params, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          item.isWant = !item.isWant;

          if (item.isWant) {
            item.want_num++;
          } else {
            item.want_num--;
          }

          store.dispatch("getUserInfo");
        }
      });
    };

    const toAutorDetails = item => {
      if (item.isWant === "null") return;
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", {
        id: item.video_id
      });
    };

    return {
      props,
      dataItem: dataItem.value,
      toAutorDetails,
      getaskVideoWant,
      videoPlay
    };
  }

};