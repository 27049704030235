import { ref, computed } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
import { Toast } from "vant";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Recharge",
  props: {
    czActive: {
      type: Number,
      default: 0
    }
  },

  setup(props, {
    emit
  }) {
    const store = useStore();
    let depositList = ref([]);
    let depositDetais = ref({
      deposit_discount: 1
    });
    let showPay = ref(false);
    let money = ref("");
    let combo_id = ref("");
    let vipList = ref([{}, {}]);
    let active = ref(0);
    let payList = ref([]);
    let pagePatList = ref([]);
    active = ref(props.czActive);
    let showDetailsPopul = ref(false);
    let payObj = ref({});
    let payActiveIndex = ref("0-0");
    const {
      depositApi,
      payConfigApi,
      depositPayApi
    } = getGlobalProperties().$api;

    const close = type => {
      if (type === 1) {
        emit("close");

        if (store.state.showLoginPopup.type === "Recharge") {
          store.commit("SET_LOGIN_POPUP", {
            show: false,
            type: "Recharge"
          });
        }
      } else {
        showDetailsPopul.value = false;
      }
    };

    const toDetails = () => {
      showDetailsPopul.value = true;
    };

    const getdepositList = () => {
      depositApi("", "get").then(res => {
        if (res.code === 0) {
          depositDetais.value = res.data.config;
          depositList.value = res.data.coin;
          vipList.value = res.data.vip_config;
        } else {
          Toast(res.message);
        }
      });
    };

    getdepositList();

    const getPayConfig = () => {
      payConfigApi("", "get").then(res => {
        if (res.code === 0) {
          payList.value = res.data.filter(item => item.pay_list && item.pay_list.length);
        }
      });
    };

    getPayConfig();

    const isPayFun = money => {
      money = Number(money);
      pagePatList.value = [];

      if (payList.value) {
        payList.value.forEach(item => {
          item.pay_list.forEach(elem => {
            if (elem.money_type === 2) {
              if (elem.money.includes(money)) {
                pagePatList.value.push(item);
              }
            } else if (elem.money_type === 1) {
              if (money >= elem.min && money <= elem.max) {
                pagePatList.value.push(item);
              }
            }
          });
        });
      }

      if (pagePatList.value.length) {
        payObj.value = pagePatList.value[0].pay_list[0];
        payObj.value.channel_type = pagePatList.value[0].channel_type;
        payActiveIndex.value = "0-0";
        return true;
      } else {
        return false;
      }
    };

    const pay = (item, type) => {
      let num = item;

      if (type === "vip") {
        num = item.real_money;
        combo_id.value = item.id;
      }

      if (isPayFun(num)) {
        money.value = num;
        showPay.value = true;
      } else {
        Toast("无可用充值渠道，请选择其它金额或稍后重试");
      }
    };

    const userInfo = computed(() => {
      return store.state.userInfo;
    });
    const fileUrl = computed(() => {
      return store.state.userInfo.top_image || "123.png";
    });

    const payItemClick = (item, elem, index, ind) => {
      payActiveIndex.value = index + "-" + ind;
      payObj.value = elem;
      payObj.value.channel_type = item.channel_type;
    };

    const depositPay = () => {
      const params = {
        amount: money.value,
        channel: payObj.value.channel,
        code: payObj.value.code,
        group_id: payObj.value.group_id,
        // money_type: 1,
        channel_type: payObj.value.channel_type || 3
      };

      if (active.value === 0) {
        // params.money_type = 2;
        params.combo_id = combo_id.value;
      }

      depositPayApi(params).then(res => {
        if (res.code === 0) {
          showPay.value = false;
          window.open(res.data.url);
        } else {
          Toast(res.message);
        }
      });
    };

    return {
      props,
      close,
      active,
      showDetailsPopul,
      fileUrl,
      userInfo,
      depositDetais,
      depositList,
      vipList,
      toDetails,
      showPay,
      pay,
      money,
      payList,
      pagePatList,
      depositPay,
      payActiveIndex,
      payItemClick
    };
  }

};