import { getGlobalProperties } from "@/assets/js/utils.js";
import SwipeComponent from "../common/SwipeComponent.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
export default {
  components: {
    SwipeComponent
  },

  setup(props, {
    emit
  }) {
    const store = useStore();
    const {
      recommendApi,
      focusSaveApi
    } = getGlobalProperties().$api;
    let page = ref(0);
    let videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user ? store.state.userInfo.focus_user.split(",") : [];
    });
    videoList.value = localStorage.getItem("RecommondComponent") ? JSON.parse(localStorage.getItem("RecommondComponent")) : [];

    const getVideoList = () => {
      recommendApi({
        page: page.value
      }, "get").then(res => {
        if (page.value === 1) {
          videoList.value = [];
        }

        refreshing.value = false;

        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });

          if (page.value === 1) {
            localStorage.setItem("RecommondComponent", JSON.stringify(res.data.rows.slice(0, 3)));
          }

          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const focusSave = async item => {
      // if (item.isFocus) {
      //   return;
      // }
      const params = {
        user_id: item.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          item.isFocus = !item.isFocus;
          store.dispatch("getUserInfo");
          page.value = 1;
          getVideoList();
        }
      });
    };

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList(); //   error.value = true;
    };

    if (page.value === 0 && videoList.value.length !== 0) {
      page.value++;
      getVideoList();
    }

    const toManhua = () => {
      // Toast("正在收集，敬请期待！");
      emit("toManhua");
    }; // getVideoList();


    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const toAutorDetails = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const toDetails = type => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: type
      });
    };

    const showShare = item => {
      store.commit("SET_SHOW_SHARE_POPUP", {
        show: true,
        videoDetails: item
      });
    };

    const onRefresh = () => {
      videoList.value = [];
      loading.value = true;
      page.value = 1;
      getVideoList();
    };

    return {
      props,
      videoList,
      loading,
      onLoad,
      error,
      videoPlay,
      finished,
      focusSave,
      toAutorDetails,
      showShare,
      toManhua,
      toDetails,
      onRefresh,
      refreshing
    };
  }

};