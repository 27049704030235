import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-59bed15a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "search"
};
const _hoisted_3 = {
  class: "featured"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_6 = {
  class: "video_list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_search = _resolveComponent("van-search");

  const _component_loading = _resolveComponent("loading");

  const _component_my_image = _resolveComponent("my-image");

  const _component_van_swipe_item = _resolveComponent("van-swipe-item");

  const _component_van_swipe = _resolveComponent("van-swipe");

  const _component_advertise_swipe = _resolveComponent("advertise-swipe");

  const _component_video_item = _resolveComponent("video-item");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_tab = _resolveComponent("van-tab");

  const _component_van_tabs = _resolveComponent("van-tabs");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("ul", _hoisted_1, [_createElementVNode("li", _hoisted_2, [_createVNode(_component_van_search, {
    background: "transparent",
    shape: "round",
    disabled: "",
    placeholder: "视频｜创作",
    onClick: _cache[0] || (_cache[0] = $event => $setup.handleShowStack('VideoSearch'))
  })])]), $setup.showLoding ? (_openBlock(), _createBlock(_component_loading, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_van_tabs, {
    key: 1,
    swipeable: "",
    active: $setup.active,
    "onUpdate:active": _cache[3] || (_cache[3] = $event => $setup.active = $event),
    animated: "",
    shrink: "",
    "swipe-threshold": 6,
    background: "#ffc0cb",
    "title-active-color": "#000000",
    "title-inactive-color": "#000000",
    "line-height": "3px"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.navs, item => {
      return _openBlock(), _createBlock(_component_van_tab, {
        "title-class": "nav_title",
        title: item.name,
        key: item.name
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "cont_body",
          style: _normalizeStyle({
            height: $setup.showBottomBanner ? 'calc(100vh - 220px)' : 'calc(100vh - 150px)'
          })
        }, [_createVNode(_component_van_list, {
          loading: $setup.loading,
          "onUpdate:loading": _cache[1] || (_cache[1] = $event => $setup.loading = $event),
          error: $setup.error,
          "onUpdate:error": _cache[2] || (_cache[2] = $event => $setup.error = $event),
          finished: $setup.finished,
          "immediate-check": false,
          "error-text": "请求失败，点击重新加载",
          "finished-text": "-我也是有底线的-",
          "loading-text": "正在获取数据...",
          onLoad: $setup.onLoad
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_swipe, {
            class: "my-swipe",
            autoplay: 3000,
            "indicator-color": "white"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bannerList, (item, index) => {
              return _openBlock(), _createBlock(_component_van_swipe_item, {
                key: index
              }, {
                default: _withCtx(() => [_createElementVNode("div", {
                  class: "banner_item",
                  onClick: $event => _ctx.toDetails(item)
                }, [_createVNode(_component_my_image, {
                  url: item.image_url
                }, null, 8, ["url"])], 8, _hoisted_4)]),
                _: 2
              }, 1024);
            }), 128))]),
            _: 2
          }, 1024), _createElementVNode("div", _hoisted_5, [_createVNode(_component_advertise_swipe)]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.videoList, item => {
            return _openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "video_item_box"
            }, [(_openBlock(), _createBlock(_component_video_item, {
              key: item.id,
              dataItem: item
            }, null, 8, ["dataItem"]))]);
          }), 128))])])]),
          _: 2
        }, 1032, ["loading", "error", "finished", "onLoad"])], 4)]),
        _: 2
      }, 1032, ["title"]);
    }), 128))]),
    _: 1
  }, 8, ["active"]))], 64);
}