import { ref, computed } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  props: ["type"],

  setup(props, {
    emit
  }) {
    const store = useStore();
    const {
      askVideoVideoApi,
      askVideoUserVideoApi,
      searchApi,
      userVideoSearchApi
    } = getGlobalProperties().$api;
    let videoList = ref([]);
    let video_title = ref("");
    let selectVideoList = ref([]);
    let list = ref([]);
    let finished = ref(false);
    let error = ref(false);
    let loading = ref(false);
    let page = ref(0);
    const recommendVideoList = computed(() => {
      return store.state.recommendVideoList;
    });

    const getVideoList = () => {
      if (props.type === 1) {
        askVideoUserVideoApi({
          page: page.value
        }, "get").then(res => {
          if (res.code === 0) {
            res.data.rows.forEach(item => {
              item.isSelect = false;
            });
            videoList.value = videoList.value.concat(res.data.rows);
            loading.value = false;

            if (res.data.rows.length === 0) {
              finished.value = true;
            }
          }
        });
      } else {
        askVideoVideoApi({
          page: page.value
        }, "get").then(res => {
          if (res.code === 0) {
            res.data.rows.forEach(item => {
              item.isSelect = false;
            });
            videoList.value = videoList.value.concat(res.data.rows);
            loading.value = false;

            if (res.data.rows.length === 0) {
              finished.value = true;
            }
          }
        });
      }
    }; // getVideoList();


    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const reach = () => {
      if (!video_title.value) {
        page.value = 1;
        videoList.value = [];
        getVideoList();
        return;
      }

      if (props.type === 1) {
        userVideoSearchApi({
          data: video_title.value
        }, "get").then(res => {
          if (res.code === 0) {
            res.data.forEach(item => {
              item.isSelect = false;
            });
            videoList.value = res.data;
            loading.value = false;
            finished.value = true;
          }
        });
      } else {
        searchApi({
          data: video_title.value,
          page: page.value
        }, "get").then(res => {
          if (res.code === 0) {
            res.data.forEach(item => {
              item.isSelect = false;
            });
            videoList.value = res.data;
            loading.value = false;
            finished.value = true;
          }
        });
      }
    };

    const setVideo = () => {
      let json = selectVideoList.value.concat(recommendVideoList);
      let arr = []; //盛放去重后数据的新数组

      for (let item1 of json) {
        //循环json数组对象的内容
        let flag = true; //建立标记，判断数据是否重复，true为不重复

        for (let item2 of arr) {
          //循环新数组的内容
          if (item1.id == item2.id) {
            //让json数组对象的内容与新数组的内容作比较，相同的话，改变标记为false
            flag = false;
          }
        }

        if (flag && item1.id) {
          //判断是否重复
          arr.push(item1); //不重复的放入新数组。  新数组的内容会继续进行上边的循环。
        }
      }

      store.commit("SET_RECOMMEND_VIDEO_LIST", arr);
      close();
    };

    const selectVideo = item => {
      item.isSelect = !item.isSelect;
      selectVideoList.value = videoList.value.filter(item => item.isSelect);
    };

    const close = () => {
      selectVideoList.value = [];
      emit("close");
    };

    return {
      props,
      close,
      setVideo,
      videoList,
      selectVideo,
      selectVideoList,
      video_title,
      reach,
      finished,
      error,
      loading,
      onLoad
    };
  }

};