import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Announcement",

  setup(props) {
    const {
      noticeApi
    } = getGlobalProperties().$api;
    const noticeList = ref([]);

    const getNoticeList = () => {
      noticeApi("", "get").then(res => {
        if (res.code === 0) {
          noticeList.value = res.data.rows;
        }
      });
    };

    getNoticeList();
    return {
      props,
      noticeList
    };
  }

};