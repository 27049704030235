import { ref, computed } from "vue"; // import { api as viewerApi } from "v-viewer";

import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
import { useStore } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  setup(props) {
    const store = useStore();
    const {
      focusSaveApi,
      headLineCommentLikeApi
    } = getGlobalProperties().$api;
    let dataItem = computed(() => {
      // 计算属性初始化加10
      return ref(props.data);
    });

    const toAutorDetails = item => {
      if (item.isFocus === "null") return;
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const focusSave = item => {
      // if (item.isFocus) {
      //   return;
      // }
      const params = {
        user_id: item.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          item.isFocus = !item.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    }; // viewerApi({ images: ["/uploads/202210/20221008190319TkNlJ.png"] });


    const getHeadLineCommentLike = item => {
      headLineCommentLikeApi({
        head_lines_id: item.id
      }, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          item.like_num++;
        }
      });
    };

    return {
      props,
      dataItem: dataItem.value,
      toAutorDetails,
      focusSave,
      getHeadLineCommentLike
    };
  }

};