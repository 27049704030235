import { ref } from "vue";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
import { Toast } from "vant";
import Clipboard from "clipboard";
export default {
  name: "RechargeRecord",

  setup(props) {
    const {
      payLogApi
    } = getGlobalProperties().$api;
    const payLogList = ref([]);
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    let showDetailsPopul = ref(false);

    const getPayLogList = () => {
      const params = {
        page: page.value,
        type: 3
      };
      payLogApi(params, "get").then(res => {
        if (res.code === 0) {
          payLogList.value = payLogList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getPayLogList();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getPayLogList();
    };

    const toDetails = () => {
      showDetailsPopul.value = true;
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    const copy = id => {
      let clipboard = new Clipboard(".copy" + id);
      clipboard.on("success", () => {
        Toast("复制成功！"); // 释放内存

        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        Toast("该浏览器不支持自动复制"); // 释放内存

        clipboard.destroy();
      });
    };

    const statusText = status => {
      let text = "";

      switch (status) {
        case 0:
          text = "待支付";
          break;

        case 1:
          text = "审核中  ";
          break;

        case 2:
          text = "已完成";
          break;

        case 3:
          text = "拒绝";
          break;

        default:
          text = "失败";
          break;
      }

      return text;
    };

    return {
      props,
      payLogList,
      loading,
      onLoad,
      error,
      finished,
      getMyDate,
      showDetailsPopul,
      close,
      toDetails,
      copy,
      statusText
    };
  }

};