import { ref } from "vue";
export default {
  setup(props) {
    const activeIndex = ref(0);
    const swipe = ref(null);

    const tabItemClick = (item, index) => {
      activeIndex.value = index;
      swipe.value.swipeTo(index);
    };

    return {
      props,
      activeIndex,
      tabItemClick,
      swipe
    };
  }

};