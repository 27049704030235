import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
import ComicsItem from "../../views/tabs/home/tabs/adultComics/ComicsItem.vue";
import ComicsItemDetails from "../../views/tabs/home/tabs/adultComics/ComicsItemDetails.vue";
export default {
  name: "MyLike",
  components: {
    ComicsItem,
    ComicsItemDetails
  },

  setup(props, {
    emit
  }) {
    const store = useStore();
    const showDetailsPopul = ref(false);
    const {
      likeListApi
    } = getGlobalProperties().$api;
    const likeList = ref([]);
    let page = ref(0);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const active = ref(0);
    const type = ref(1);
    const showDetailsPopul1 = ref(false);
    const key = ref("");

    const close = type => {
      if (type === 1) {
        emit("close");
      } else {
        showDetailsPopul.value = false;
      }
    };

    const close1 = () => {
      showDetailsPopul1.value = false;
    };

    const activeIndex1 = ref(0);
    const activeIndex2 = ref(0);
    const labelLists = ref([{
      label: "全部"
    }, {
      label: "自定义"
    }]);

    const labelItemClick = (item, index, type) => {
      if (item.label !== "自定义") {
        if (type === 1) {
          activeIndex1.value = index;
        } else if (type === 2) {
          activeIndex2.value = index;
        }
      } else {
        showDetailsPopul.value = true;
      }
    };

    const getLikeList = () => {
      const params = {
        type: type.value,
        all: 2,
        page: page.value
      };
      likeListApi(params, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          loading.value = false;
          likeList.value = likeList.value.concat(res.data.rows);

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    }; // getLikeList();


    const onLoad = () => {
      loading.value = true;
      page.value++;
      getLikeList();
    };

    const onClickTab = () => {
      if (active.value === 0) {
        type.value = 1;
      } else {
        type.value = 2;
      }

      finished.value = false;
      likeList.value = [];
      page.value = 1;
      getLikeList();
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const comics_id = ref("");

    const toDetails = item => {
      comics_id.value = item.id;
      key.value = Math.random();
      showDetailsPopul1.value = true;
    };

    return {
      props,
      close,
      close1,
      labelLists,
      activeIndex1,
      activeIndex2,
      active,
      labelItemClick,
      showDetailsPopul,
      onClickTab,
      likeList,
      videoPlay,
      loading,
      onLoad,
      error,
      finished,
      toDetails,
      showDetailsPopul1,
      key,
      comics_id
    };
  }

};