import HistoryComponent from "@/views/tabs/home/tabs/history/HistoryComponent.vue";
import { ref, computed, onActivated } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { getGlobalProperties, advertiseDetails } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  components: {
    HistoryComponent
  },

  setup(props) {
    const store = useStore();
    const isLoging = ref(false);
    const showDialog = ref(false);
    const showDialog2 = ref(false);
    const popupText = ref("");
    const pupupValue = ref("");
    const email = ref("");
    let key = ref("");
    const userInfo = computed(() => {
      return store.state.userInfo;
    });
    const fileUrl = computed(() => {
      return store.state.userInfo.top_image || "123.png";
    });
    const {
      homePageApi,
      groupApi,
      inviteSaveApi,
      modifyEmailApi,
      advertiseApi
    } = getGlobalProperties().$api;
    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });

    if (!store.state.userInfo.email) {// showDialog2.value = true;
    }

    const okBtns2 = () => {
      if (email.value === "") {
        Toast("邮箱账号不能为空");
        return;
      }

      const toast = Toast.loading({
        message: "更新中...",
        forbidClick: true,
        duration: 0
      });
      modifyEmailApi({
        email: email.value
      }).then(res => {
        Toast(res.message);
        toast.clear();

        if (res.code === 0) {
          showDialog2.value = false;
          store.dispatch("getUserInfo");
        }
      });
    };

    const okBtns = () => {
      if (pupupValue.value === "") {
        Toast(popupText.value + "不能为空");
      }

      if (popupText.value === "兑换码") {
        inviteSaveApi({
          invite_code: pupupValue.value
        }).then(res => {
          Toast(res.message);
        });
      } else {
        inviteSaveApi({
          invite_code: pupupValue.value
        }).then(res => {
          Toast(res.message);
        });
      }

      showDialog.value = false;
    }; // const navList1 = ref([
    //   {
    //     label: "我的喜欢",
    //     path: "MyLike",
    //     showHeader: "null",
    //     icon: new URL("./images/iconActiveLike.png", import.meta.url).href,
    //   },
    //   {
    //     label: "我的消息",
    //     path: "MyMessage",
    //     icon: new URL("./images/iconServerMsg.png", import.meta.url).href,
    //   },
    //   {
    //     label: "在线客服",
    //     path: "OnlineService",
    //     icon: new URL("./images/iconServerCustomer.png", import.meta.url).href,
    //   },
    //   {
    //     label: "官方公告",
    //     path: "Announcement",
    //     icon: new URL("./images/iconServerBulletin.png", import.meta.url).href,
    //   },
    //   {
    //     label: "设置",
    //     path: "MySet",
    //     icon: new URL("./images/iconServerSet.png", import.meta.url).href,
    //   },
    //   {
    //     label: "加入官方群",
    //     path: "",
    //     icon: new URL("./images/iconServerGroup.png", import.meta.url).href,
    //   },
    // ]);
    // const navList2 = ref([]);


    const navList1 = ref([// {
    //   label: "约炮解锁",
    //   path: "",
    //   icon: new URL("./images/iconActiveMyDating.png", import.meta.url).href,
    // },
    // {
    //   label: "钱包",
    //   path: "MyWallet",
    //   icon: new URL("./images/iconActiveWallet.png", import.meta.url).href,
    // },
    {
      label: "分享无限看",
      path: "ShareFreeWatch",
      showHeader: "null",
      icon: new URL("./images/iconActiveShare.png", import.meta.url).href
    }, {
      label: "我的喜欢",
      path: "MyLike",
      showHeader: "null",
      icon: new URL("./images/iconActiveLike.png", import.meta.url).href
    }, // {
    //   label: "粉丝团",
    //   path: "",
    //   icon: new URL("./images/iconActiveFans.png", import.meta.url).href,
    // },
    // {
    //   label: "代理赚钱",
    //   path: "AgentMakesMoney",
    //   icon: new URL("./images/iconActiveMakeMoney.png", import.meta.url).href,
    // },
    {
      label: "我的微贴",
      path: "MyMicropost",
      showHeader: "null",
      icon: new URL("./images/iconActivePost.png", import.meta.url).href
    }, {
      label: "观看记录",
      path: "HistoryComponent",
      showHeader: "null",
      icon: new URL("./images/gkjl.png", import.meta.url).href
    }]);
    const navList2 = ref([// {
    //   label: "认证管理",
    //   path: "",
    //   icon: new URL("./images/iconServerAttest.png", import.meta.url).href,
    // },
    {
      label: "我的消息",
      path: "MyMessage",
      icon: new URL("./images/iconServerMsg.png", import.meta.url).href
    }, {
      label: "在线客服",
      path: "OnlineService",
      icon: new URL("./images/iconServerCustomer.png", import.meta.url).href
    }, {
      label: "官方公告",
      path: "Announcement",
      icon: new URL("./images/iconServerBulletin.png", import.meta.url).href
    }, {
      label: "设置",
      path: "MySet",
      icon: new URL("./images/iconServerSet.png", import.meta.url).href
    }, {
      label: "加入官方群",
      path: "",
      icon: new URL("./images/iconServerGroup.png", import.meta.url).href
    }, // {
    //   label: "应用中心",
    //   path: "",
    //   icon: new URL("./images/iconServerApp.png", import.meta.url).href,
    // },
    // {
    //   label: "兑换码",
    //   path: "",
    //   icon: new URL("./images/iconServerExchangeCode.png", import.meta.url)
    //     .href,
    // },
    {
      label: "邀请码",
      path: "",
      icon: new URL("./images/iconServerInviteCode.png", import.meta.url).href
    }]);
    const showDetailsPopul = ref(false);
    const showHeader = ref(true);
    const detailsTitle = ref("");
    const detailsView = ref("");
    const gFQunUrl = ref("");

    const toDetails = item => {
      showHeader.value = true;
      key.value = Math.random();

      if (item.showHeader === "null") {
        showHeader.value = false;
      }

      if (item.path) {
        showDetailsPopul.value = true;
        detailsView.value = item.path;
        detailsTitle.value = item.label;
      } else if (item.url) {
        // window.open(item.url);
        location.href = item.url;
      } else if (item.label === "加入官方群") {
        // window.open(gFQunUrl.value);
        location.href = gFQunUrl.value; // toRouter("/home");
      } else {
        if (item.label === "兑换码" || item.label === "邀请码") {
          // Toast("暂未开放");
          popupText.value = item.label;
          pupupValue.value = "";
          showDialog.value = true;
        } else {
          Toast("暂未开放");
        }
      }
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    const toLogin = () => {
      // store.commit("SET_LOGIN_POPUP", { show: true, type: "Login" });
      router.push("/login");
    };

    const toRouter = path => {
      router.push(path);
    };

    const userData = ref({});

    const homePageData = () => {
      homePageApi("", "get").then(res => {
        if (res.code === 0) {
          userData.value = res.data;
          let data = store.state.userInfo;
          data = { ...data,
            ...res.data
          };
          store.commit("SET_USER_INFO", data);
        }
      });
    };

    const getGroup = () => {
      groupApi("", "get").then(res => {
        if (res.code === 0) {
          gFQunUrl.value = res.data.group_num;
        }
      });
    };

    const bannerList = ref([]);

    const getBanner = () => {
      advertiseApi({
        type: 5
      }, "get").then(res => {
        if (res.code === 0) {
          bannerList.value = res.data.rows;
        }
      });
    };

    getBanner();

    const toBannerDetails = item => {
      if (item.link) {
        // window.open(item.link);
        advertiseDetails(item.link);
      }
    };

    getGroup();
    homePageData();
    onActivated(() => {
      homePageData();
    });
    return {
      props,
      isLoging,
      navList1,
      navList2,
      toDetails,
      showDetailsPopul,
      close,
      detailsTitle,
      detailsView,
      showHeader,
      showDialog,
      popupText,
      pupupValue,
      okBtns,
      toLogin,
      userInfo,
      userData,
      fileUrl,
      key,
      showBottomBanner,
      showDialog2,
      email,
      okBtns2,
      bannerList,
      toBannerDetails,
      toRouter
    };
  }

};