import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  name: "HotAuthorInfo",

  setup(props, {
    emit
  }) {
    const {
      autoerVideoApi,
      focusSaveApi
    } = getGlobalProperties().$api;
    const store = useStore();
    let page = ref(1);
    let finished = ref(false);
    let error = ref(false);
    let loading = ref(false);
    let videoList = ref([]);
    let authorInfo = ref({});
    let detailsTitle = ref("");
    let videoId = ref("");
    let showDetailsPopul = ref(false);
    const showLoading = ref(true);
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });

    const getAuthorInfo = () => {
      if (page.value === 1) {
        showLoading.value = true;
        finished.value = false;
      }

      detailsTitle.value = store.state.videoDetails.nickname;
      autoerVideoApi({
        user_id: store.state.videoDetails.user_id,
        page: page.value
      }, "get").then(res => {
        showLoading.value = false;

        if (res.code === 0) {
          res.data.isFocus = isFocus.value.includes(store.state.videoDetails.user_id + "");
          authorInfo.value = res.data;
          videoList.value = videoList.value.concat(res.data.rows.rows);
          loading.value = false;

          if (!res.data.rows || res.data.rows.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getAuthorInfo();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getAuthorInfo();
    };

    const videoPlay = item => {
      store.commit("SET_VIDEO_DETAILS", item);
      videoId.value = item.id;
      showDetailsPopul.value = true;
    };

    const focusSave = () => {
      // if (authorInfo.value.isFocus) {
      //   return;
      // }
      const params = {
        user_id: store.state.videoDetails.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          authorInfo.value.isFocus = !authorInfo.value.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    };

    const close = () => {
      emit("close");

      if (store.state.showLoginPopup.type !== "VideoMrtj" && store.state.showLoginPopup.type !== "VideoDetails") {
        store.commit("SET_LOGIN_POPUP", {
          show: false,
          type: "HotAuthorInfo"
        });
      }
    };

    const close1 = () => {
      showDetailsPopul.value = false;
    };

    const showDetailsPopuls = computed(() => {
      // 计算属性初始化加10
      return store.state.showLoginPopup;
    });
    watch(showDetailsPopuls, () => {
      if (showDetailsPopuls.value.show) {
        page.value = 1;
        videoList.value = [];
        getAuthorInfo();
      }
    });
    return {
      props,
      detailsTitle,
      close,
      videoList,
      videoPlay,
      loading,
      onLoad,
      error,
      finished,
      authorInfo,
      focusSave,
      showDetailsPopul,
      close1,
      videoId,
      showLoading
    };
  }

};