import { useStore } from "vuex";
import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import router from "@/router";
import request from "@/assets/js/request.js";
import { Toast } from "vant";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",

  setup(props) {
    const store = useStore();
    const option = ref([{
      text: "+86",
      value: "+86"
    }]);
    const value = ref("+86");

    const close = () => {
      location.href = "/home";
    };

    const {
      loginApi,
      registerAPI
    } = getGlobalProperties().$api;
    let params = ref({
      username: "",
      password: "",
      device_type: 1,
      invite_code: "z5fyr"
    });

    const register = () => {
      if (!params.value.username) {
        Toast("请输入账号/手机号");
        return;
      }

      if (!params.value.password) {
        Toast("请输入密码");
        return;
      }

      registerAPI(params.value).then(res => {
        console.log(res);

        if (res.code === 0) {
          res.data.nickname = params.value.nickname;
          store.commit("SET_USER_INFO", res.data);
          request.post("https://ndisa.popowpo.com/in/point/send", {
            eventCode: "Register-001",
            //埋点标识，安装点位填写"Install-001" 注册点位填写“Register-001” ,
            channel: "thd",
            //渠道标识,
            uuid: store.state.hg_oauth_id,
            //设备唯一标识,
            platformType: "iOS",
            uid: res.data.uid,
            account: res.data.username || res.data.nickname,
            domain: "thd",
            //app名称,
            sysVersion: "1.1.1",
            //app版本号,
            h5Version: "1.1.1" //h5版本

          });
          window.location = "/";
        } else {
          Toast(res.message);
        }
      });

      if (!store.state.userInfo.username) {// params.value.nickname = store.state.userInfo.nickname;
        // modifyUserApi(params.value).then((res) => {
        //   console.log(res);
        //   if (res.code === 0) {
        //     window.location = "/";
        //   } else {
        //     Toast(res.message);
        //   }
        // });
      } else {// Toast("您已经注册过了");
        // params.value.nickname = getRandomName();
        // registerAPI(params.value).then((res) => {
        //   console.log(res);
        //   if (res.code === 0) {
        //     res.data.nickname = params.value.nickname;
        //     store.commit("SET_USER_INFO", res.data);
        //     window.location = "/";
        //   } else {
        //     Toast(res.message);
        //   }
        // });
      }
    };

    const login = () => {
      if (!params.value.username) {
        Toast("请输入账号/手机号");
        return;
      }

      if (!params.value.password) {
        Toast("请输入密码");
        return;
      }

      loginApi(params.value).then(res => {
        console.log(res);

        if (res.code === 0) {
          store.commit("SET_USER_INFO", res.data);
          store.commit("SET_HG_OAUTH_ID", "login");
          window.location = "/";
        } else {
          Toast(res.message);
        }
      });
    };

    const toDetails = path => {
      router.push(path);
    };

    return {
      props,
      close,
      register,
      params,
      login,
      option,
      value,
      toDetails
    };
  }

};