import { ref } from "vue";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
export default {
  name: "PromotionRecord",

  setup(props) {
    const {
      inviteLogApi
    } = getGlobalProperties().$api;
    const inviteLogList = ref([]);
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);

    const getinviteLogList = () => {
      const params = {
        page: page.value
      };
      inviteLogApi(params, "get").then(res => {
        if (res.code === 0) {
          inviteLogList.value = inviteLogList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getinviteLogList();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getinviteLogList();
    };

    return {
      props,
      inviteLogList,
      loading,
      onLoad,
      error,
      finished,
      getMyDate
    };
  }

};