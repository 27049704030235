import { ref, computed } from "vue";
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  setup(props, {
    emit
  }) {
    const dataItem = computed(() => {
      // 计算属性初始化加10
      return ref(props.data);
    });
    return {
      props,
      dataItem: dataItem.value
    };
  }

};