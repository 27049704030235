import { getGlobalProperties, advertiseDetails } from "@/assets/js/utils.js";
import { ref } from "vue";
export default {
  setup(props) {
    const {
      advertiseApi
    } = getGlobalProperties().$api;
    let bannerList = ref([]);

    const getBanner = () => {
      advertiseApi({
        type: 1
      }, "get").then(res => {
        if (res.code === 0) {
          bannerList.value = res.data.rows;
        }
      });
    };

    getBanner();

    const toDetails = item => {
      if (item.link) {
        // countApi({ url: item.link }, "get");
        // window.open(item.link);
        advertiseDetails(item.link);
      }
    };

    return {
      props,
      bannerList,
      toDetails
    };
  }

};