import ComicsContent from "./ComicsContents.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  components: {
    ComicsContent
  },
  props: ["comics_directory_id", "comics_id", "comics_status"],

  setup(props, {
    emit
  }) {
    const {
      comicsPayApi
    } = getGlobalProperties().$api;
    const store = useStore();
    const showMl = ref(false);
    const detailsData = ref({});
    const showLoading = ref(true);
    const directoryList = ref([]);
    directoryList.value = store.state.directoryList;

    const comicsPay = comics_directory_id => {
      showLoading.value = true;
      detailsData.value = {};
      comicsPayApi({
        comics_directory_id: comics_directory_id
      }, "get").then(res => {
        console.log(res);
        showLoading.value = false;

        if (res.code === 0) {
          detailsData.value = res.data;
        }
      });
    };

    comicsPay(props.comics_directory_id);

    const close = () => {
      emit("close");
    };

    const toFenxian = () => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "ShareFreeWatch"
      });
    };

    const lockMl = () => {
      showMl.value = true;
    };

    const startReading = item => {
      comicsPay(item.id);
      showMl.value = false;
    };

    const getComicsDirectory = list => {
      directoryList.value = list;
    };

    const prev = () => {
      comicsPay(directoryList.value[detailsData.value.sort + 1].id);
    };

    const next = () => {
      comicsPay(directoryList.value[detailsData.value.sort - 1].id);
    };

    return {
      props,
      close,
      toFenxian,
      showMl,
      lockMl,
      detailsData,
      showLoading,
      startReading,
      getComicsDirectory,
      directoryList,
      prev,
      next
    };
  }

};