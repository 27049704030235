import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "MyImage",
  props: {
    url: {
      type: String,
      default: ""
    },
    round: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const store = useStore();
    const baseUrl = computed(() => {
      return store.state.baseUrl;
    });
    const imgUrl = computed(() => {
      return props.url;
    });

    const load = e => {
      console.log(e);
      let oImg = document.getElementsByClassName("van-image__img");
      Array.prototype.forEach.call(oImg, function (item) {
        item.setAttribute("class", "animation");
      });
    };

    return {
      props,
      imgUrl,
      baseUrl,
      load
    };
  }

};