export default {
  name: "MySet",

  setup(props) {
    const logout = () => {
      localStorage.clear();
      location.href = "/home";
    };

    return {
      props,
      logout
    };
  }

};