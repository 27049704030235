import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-67eb4da2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  id: "explore"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_classification = _resolveComponent("classification");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "tab_cont webkit-overflow-scrolling-touch",
    style: _normalizeStyle({
      height: $setup.showBottomBanner ? 'calc(100vh - 132px)' : 'calc(100vh - 62px)'
    })
  }, [_createVNode(_component_classification)], 4)]);
}