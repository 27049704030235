import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a9b9c138"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "comicsItem"
};
const _hoisted_2 = {
  class: "img_cont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_my_image = _resolveComponent("my-image");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_my_image, {
    url: $setup.dataItem.image
  }, null, 8, ["url"])]), _createElementVNode("p", null, _toDisplayString($setup.dataItem.title), 1)]);
}