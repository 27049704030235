import { ref, computed } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
import { useStore } from "vuex";
export default {
  name: "AgentMakesMoney",

  setup(props) {
    const showDialog = ref(false);
    const store = useStore();
    const commissioDetails = ref({});
    const commissionLogList = ref([]);
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const {
      commissionApi,
      commissionLogApi
    } = getGlobalProperties().$api;

    const ruleDetails = () => {
      showDialog.value = true;
    };

    const getCommission = () => {
      commissionApi("", "get").then(res => {
        if (res.code === 0) {
          commissioDetails.value = res.data;
        } else {
          Toast(res.message);
        }
      });
    };

    getCommission();

    const getCommissionLog = () => {
      const params = {
        page: page.value
      };
      commissionLogApi(params, "get").then(res => {
        if (res.code === 0) {
          commissionLogList.value = commissionLogList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getCommissionLog();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getCommissionLog();
    };

    const close = () => {
      showDialog.value = false;
    };

    const rule_url = computed(() => {
      return store.state.userInfo.rule_url;
    });
    return {
      props,
      close,
      showDialog,
      ruleDetails,
      commissioDetails,
      rule_url,
      commissionLogList,
      loading,
      onLoad,
      error,
      finished
    };
  }

};