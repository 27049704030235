import { ref } from "vue";
export default {
  name: "NoData",
  props: {
    text: {
      type: String,
      default: "什么都没有，好空虚啊"
    },
    color: {
      type: String,
      default: "#000"
    }
  },

  setup(props) {
    const noDataText = ref(props.text);
    const color = ref(props.color);
    return {
      props,
      noDataText,
      // eslint-disable-next-line vue/no-dupe-keys
      color
    };
  }

};