import { computed, ref } from "vue";
import FocusComponent from "./tabs/focus/FocusComponent.vue"; // import RecommondComponent from "./tabs/recommond/RecommondComponent.vue";

import LatestComponent from "./tabs/latest/LatestComponent.vue";
import StudioVideo from "./tabs/studio/StudioVideo.vue";
import todaysHeadlines from "./tabs/todays/todaysHeadlines.vue";
import AdultComics from "./tabs/adultComics/AdultComics.vue";
import Featured from "../explore/components/FeaturedComponent.vue";
import SwipeComponent from "./tabs/common/SwipeComponent.vue";
import { useStore } from "vuex";
import { Dialog } from "vant";
import VideoList from "./tabs/common/VideoList.vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import HistoryComponent from "./tabs/history/HistoryComponent.vue";
import { Toast } from "vant";
import router from "@/router";
export default {
  components: {
    FocusComponent,
    Featured,
    LatestComponent,
    StudioVideo,
    todaysHeadlines,
    AdultComics,
    SwipeComponent,
    VideoList,
    HistoryComponent
  },
  name: "HomeView",

  setup(props, {
    emit
  }) {
    const store = useStore(); // store.dispatch("getUserInfo");

    const active = ref(0);
    const showAdultComicsPopul = ref(false);
    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });
    const showDown = ref(false);
    let navs = ref([// {
    //   name: "关注",
    //   id: "gz",
    //   component: "FocusComponent",
    // },
    {
      name: "最新更新",
      id: "jx",
      component: "RecommondComponent"
    }, {
      name: "游戏",
      id: "yx",
      component: "RecommondComponent"
    }, {
      name: "热门",
      id: "rm",
      component: "todaysHeadlines"
    } // {
    //   name: "最新",
    //   id: "zx",
    //   component: "LatestComponent",
    // },
    // {
    //   name: "漫画",
    //   id: "mh",
    //   component: "AdultComics",
    // },
    ]);
    let currentComponent = computed(() => {
      return navs.value[active.value]["component"];
    });
    const gemeList = ref([]);
    const {
      homeMenuApi,
      getGameListApi,
      getLoginUrlApi,
      cpGetLoginUrlApi,
      qyGetLoginUrlApi,
      cpWithdrawApi
    } = getGlobalProperties().$api;

    const handleShowStack = path => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: path
      });
    };

    const getGameList = () => {
      getGameListApi().then(res => {
        cpWithdrawApi({
          company_id: 1
        });

        if (res.code === 0) {
          res.data.forEach(item => {
            if (item.data.length) {
              gemeList.value.push(...item.data);
            }
          });
          console.log(gemeList.value);
        }
      });
    };

    getGameList();

    const onClickTab = ({
      title
    }) => {
      if (title === "游戏") {
        router.push("/vip");
      }
    };

    const getLoginUrl = (item, code) => {
      const toast = Toast.loading({
        message: "Loading...",
        forbidClick: true
      });
      sessionStorage.setItem("__code", code);
      sessionStorage.setItem("__gameId", item.id);

      if (code === "zr") {
        getLoginUrlApi({
          company_id: item.id
        }, "post").then(res => {
          toast.close();

          if (res.code === 0 && res.data) {
            router.replace({
              path: "/iframe",
              query: {
                title: item.name,
                url: res.data.url
              }
            }); // location.href = res.data.url
          }
        }).catch(() => {
          toast.close();
        });
      } else if (code === "cp") {
        cpGetLoginUrlApi({
          company_id: item.id
        }, "post").then(res => {
          toast.close();

          if (res.code === 0 && res.data) {
            // router.replace({
            //   path: "/iframe",
            //   query: {
            //     title: item.name,
            //     url: res.data.url,
            //   },
            // });
            location.href = res.data.url;
          }
        }).catch(() => {
          toast.close();
        });
      } else if (code === "dz") {
        qyGetLoginUrlApi({
          company_id: item.id
        }, "post").then(res => {
          toast.close();

          if (res.code === 0 && res.data) {
            router.replace({
              path: "/iframe",
              query: {
                title: item.name,
                url: res.data.url
              }
            }); // location.href = res.data.url;
          }
        }).catch(() => {
          toast.close();
        });
      }
    };

    const getMenu = () => {
      homeMenuApi(" ", "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          navs.value = navs.value.concat(res.data);
          navs.value.push({
            name: "漫画",
            id: "mh",
            component: "AdultComics"
          });
        }
      });
    };

    getMenu();

    const toFaxian = () => {
      emit("toFaxian");
    };

    const releaseValue = () => {
      Dialog.alert({
        title: "温馨提示",
        cancelButtonText: "确认",
        className: "okAlertPopup",
        message: "联系管理员获取上传功能权限"
      }).then(() => {// window.open(store.state.baseUrl.h5_download_url);
      });
    };

    const toManhua = () => {
      showAdultComicsPopul.value = true;
    };

    const close = () => {
      showAdultComicsPopul.value = false;
    };

    const showHistoryPopul = ref(false);
    const key = ref("");

    const toHistory = () => {
      key.value = Math.random();
      showHistoryPopul.value = true;
    };

    const close1 = () => {
      showHistoryPopul.value = false;
    };

    const downloadApp = () => {
      window.open(store.state.baseUrl.download_url);
    };

    return {
      props,
      navs,
      active,
      currentComponent,
      handleShowStack,
      toFaxian,
      releaseValue,
      toManhua,
      showAdultComicsPopul,
      close,
      showBottomBanner,
      toHistory,
      showHistoryPopul,
      close1,
      key,
      showDown,
      downloadApp,
      getLoginUrl,
      gemeList,
      onClickTab
    };
  }

};