import { getGlobalProperties } from "@/assets/js/utils.js";
import SwipeComponent from "./SwipeComponent.vue";
import VideoItem from "./VideoItem.vue";
import { ref } from "vue";
export default {
  components: {
    SwipeComponent,
    VideoItem
  },
  props: ['id'],

  setup(props) {
    const {
      menuTypeApi
    } = getGlobalProperties().$api;
    const page = ref(0);
    const finished = ref(false);
    const videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    const refreshing = ref(false);

    const getVideoList = () => {
      menuTypeApi({
        menu_id: props.id,
        page: page.value
      }, "get").then(res => {
        console.log(res);

        if (page.value === 1) {
          videoList.value = [];
        }

        refreshing.value = false;

        if (res.code === 0) {
          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const onRefresh = () => {
      videoList.value = [];
      loading.value = true;
      page.value = 1;
      getVideoList();
    };

    return {
      props,
      videoList,
      loading,
      onLoad,
      error,
      finished,
      onRefresh,
      refreshing
    };
  }

};