import { useStore } from "vuex";
import { ref, nextTick } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import router from "@/router";
import { useRoute } from "vue-router";
import request from "@/assets/js/request.js";
import { Toast } from "vant";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",

  setup(props) {
    const store = useStore();
    const option = ref([{
      text: "+86",
      value: "+86"
    }]);
    const value = ref("+86");
    const route = useRoute();

    const close = () => {
      store.commit("SET_LOGIN_POPUP", {
        show: false,
        type: "VideoDetails"
      });
      history.back();
    };

    const time = ref(60 * 1000);
    const showFs = ref(true);
    const {
      registerAPI,
      sendCodeApi
    } = getGlobalProperties().$api;
    const params = ref({
      username: "",
      password: "",
      code: "",
      invite_code: "",
      device_type: 0,
      area: 86
    });
    const password2 = ref("");
    nextTick(() => {
      params.value.invite_code = route.query.invite_code || sessionStorage.getItem('invite_code') || '';
    });

    const register = () => {
      if (!params.value.username) {
        Toast("请输入手机号");
        return;
      }

      if (!params.value.code) {
        Toast("请输入验证码");
        return;
      }

      if (!params.value.password) {
        Toast("请输入密码");
        return;
      }

      if (!password2.value) {
        Toast("请输入确认密码");
        return;
      }

      if (params.value.password !== password2.value) {
        Toast("两次输入的密码不一致");
        return;
      }

      registerAPI(params.value).then(res => {
        console.log(res);

        if (res.code === 0) {
          router.push("/login");
        } else {
          Toast(res.message);
        }
      });
    };

    const sendCode = () => {
      if (!params.value.username) {
        Toast("请输入手机号");
        return;
      }

      sendCodeApi({
        phone: params.value.username
      }, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          time.value = 60 * 1000;
          showFs.value = false;
        }
      });
    };

    const finish = () => {
      showFs.value = true;
    };

    const toDetails = path => {
      router.push(path);
    };

    return {
      props,
      close,
      register,
      params,
      option,
      value,
      toDetails,
      password2,
      sendCode,
      time,
      showFs,
      finish
    };
  }

};