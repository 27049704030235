import { useStore } from "vuex";
import { ref, computed } from "vue";
export default {
  name: "MyWallet",

  setup(props) {
    const store = useStore();
    let showDetailsPopul = ref(false);
    let showHeader = ref(false);
    let detailsTitle = ref(false);
    let detailsView = ref("");
    let active = ref(0);
    store.dispatch("getUserInfo");

    const toDetails = item => {
      showHeader.value = true;

      if (item.showHeader === "null") {
        showHeader.value = false;
      }

      detailsView.value = item.path;
      detailsTitle.value = item.title;
      active.value = item.active;
      console.log(active.value);
      showDetailsPopul.value = true;
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    const userInfo = computed(() => {
      return store.state.userInfo;
    });
    return {
      props,
      userInfo,
      showDetailsPopul,
      detailsView,
      close,
      showHeader,
      toDetails,
      detailsTitle,
      active
    };
  }

};