import SwiperCore, { Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss";
SwiperCore.use([Scrollbar, Autoplay]);
import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup(props) {
    const store = useStore();
    let activeIndex = ref(0);
    let showDetailsPopul = ref(false);
    let searchText = ref("");
    let menuList = ref([]);
    let labelList = ref([]);
    let hotAuthorList = ref([]);
    let popupType = ref(1); // let user_id = ref("");

    let menu_id = ref("");
    let label_id = ref("");
    let menuTetx = ref("");
    const shoeLoading = ref(true);
    const {
      homeMenuApi,
      hotAuthorApi
    } = getGlobalProperties().$api;
    let swipe = ref(null);

    const tabItemClick = (item, index) => {
      activeIndex.value = index;
      menu_id.value = item.id;
      label_id.value = "";
      menuTetx.value = item.name;
      swipe.value.swipeTo(index);
    };

    const getMenuList = () => {
      shoeLoading.value = true;
      homeMenuApi("", "get").then(res => {
        shoeLoading.value = false;

        if (res.code === 0) {
          menuList.value = res.data;

          if (res.data.length) {
            menu_id.value = res.data[0].id;
            menuTetx.value = res.data[0].name;
          }
        }
      });
    };

    getMenuList();

    const getHotAuthor = () => {
      hotAuthorApi("", "get").then(res => {
        if (res.code === 0) {
          hotAuthorList.value = res.data;
        }
      });
    };

    getHotAuthor();

    const labelSearch = item => {
      searchText.value = item.name;
      label_id.value = item.id;
      popupType.value = 1;
      showDetailsPopul.value = true;
    };

    const toAutorDetails = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const menuAll = () => {
      popupType.value = 1;
      label_id.value = "";
      searchText.value = menuTetx.value;
      showDetailsPopul.value = true;
    };

    const close = () => {
      showDetailsPopul.value = false; // searchText.value = "";
    };

    return {
      props,
      activeIndex,
      tabItemClick,
      swipe,
      menuList,
      labelList,
      hotAuthorList,
      close,
      showDetailsPopul,
      searchText,
      labelSearch,
      toAutorDetails,
      popupType,
      menu_id,
      label_id,
      menuAll,
      menuTetx,
      shoeLoading
    };
  }

};