import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
import { ref } from "vue";
export default {
  name: "MyMessage",

  setup(props) {
    const active = ref(0);
    const messageList = ref([]);
    const noData = ref(false);
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const {
      messageApi
    } = getGlobalProperties().$api;

    const getMessageList = () => {
      loading.value = true;
      const params = {
        type: active.value,
        page: page.value
      };
      messageApi(params, "get").then(res => {
        if (res.code === 0) {
          messageList.value = messageList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            noData.value = true;
            finished.value = true;
          }
        } else {
          noData.value = true;
        }
      });
    };

    const change = () => {
      noData.value = false;
      finished.value = false;
      page.value = 0;
      messageList.value = [];
      onLoad();
    };

    const onLoad = () => {
      page.value++;
      getMessageList();
    };

    getMessageList();
    return {
      props,
      active,
      messageList,
      getMyDate,
      getMessageList,
      noData,
      loading,
      onLoad,
      error,
      finished,
      change
    };
  }

};