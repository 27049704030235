import { useStore } from "vuex";
import { ref, nextTick, watch, computed } from "vue"; // import Hls from "hls.js";
// import DPlayer from "dplayer";
// import Videojs from "video.js/dist/video.min";

import { getGlobalProperties, getMyDate, advertiseDetails } from "@/assets/js/utils.js";
import { Toast, Dialog } from "vant";
import router from "@/router";
import AskForVideoDetails from "@/views/tabs/community/components/AskForVideoDetails.vue";
export default {
  name: "VideoDetails",
  components: {
    AskForVideoDetails
  },
  props: ["details_type"],

  setup(props, {
    emit
  }) {
    const store = useStore();
    const {
      videoDetailsApi,
      similarApi,
      likeApi,
      commentApi,
      commentSaveApi,
      focusSaveApi,
      advertiseApi,
      // payApi,
      feedbackSaveApi,
      modifyPhoneApi,
      askVideoContinuationApi,
      videoDetailApi
    } = getGlobalProperties().$api;
    const detailsData = ref({});
    let bannerList = ref([]);
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const commentList = ref([]);
    let active = ref(0);
    const isPay = ref(false);
    const showDialog = ref(false); // let hls = null;

    let player = null;
    const showSearchPopup = ref(false);
    const searchText = ref("");
    const key = ref("");
    const label_name = ref("");
    const videoBoxId = ref("videoBox");
    const showDialog3 = ref(false);
    const phone = ref("");
    const videoImgs = ref({});
    const showStart = ref(false);
    const showEnd = ref(false);
    const showStop = ref(false);
    const options = ref({
      autoplay: false,
      muted: false,
      preload: "auto",
      // 预加载
      controls: true,
      poster: "",
      url: "",
      type: "application/x-mpegURL"
    });
    detailsData.value = localStorage.getItem("__detailsData") ? JSON.parse(localStorage.getItem("__detailsData")) : {};
    const showLoading = ref(true);
    let isLike = computed(() => {
      return store.state.userInfo.like_path ? store.state.userInfo.like_path.split(",") : [];
    });
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user ? store.state.userInfo.focus_user.split(",") : [];
    });

    const getVideoDetailsImg = () => {
      showStop.value = false;
      showEnd.value = false;
      videoDetailApi("", "get").then(res => {
        if (res.code === 0) {
          videoImgs.value = res.data;

          if (res.data.start) {
            showStart.value = true;
          }
        }
      });
    };

    getVideoDetailsImg();

    const getBanner = () => {
      advertiseApi({
        type: 2
      }, "get").then(res => {
        if (res.code === 0) {
          bannerList.value = res.data.rows;
        }
      });
    };

    getBanner();

    const toBannerDetails = item => {
      if (item.link) {
        // countApi({ url: item.link }, "get");
        // window.open(item.link);
        advertiseDetails(item.link);
      }
    };

    const initVideo = id => {
      // if (detailsData.value.error_type > 0) {
      //   showAlert();
      //   return;
      // }
      const length = detailsData.value.url.indexOf("sign=");
      let url = ""; // if (detailsData.value.play === 0 && detailsData.value.has_buy === 0) {
      //   url =
      //     detailsData.value.url.slice(0, length + 5) +
      //     detailsData.value.url.slice(length + 15) +
      //     `&start=${detailsData.value.start}&end=${detailsData.value.end}`;
      // } else {

      url = detailsData.value.url.slice(0, length + 5) + detailsData.value.url.slice(length + 15); // }

      if (player) {
        console.log(player);
        player.dispose();
      }

      options.value.poster = detailsData.value.image; // poster="${options.value.poster}"

      options.value.src = url;
      options.value.type = url.indexOf(".m3u8") > -1 ? "application/x-mpegURL" : "video/mp4";
      player = videojs("videoPlayer" + id, options.value); //关键代码， 动态设置src，才可实现换台操作

      player.src([{
        src: url,
        type: url.indexOf(".m3u8") > -1 ? "application/x-mpegURL" : "video/mp4"
      }]); // showLoading.value = false;

      if (player) {
        player.on("playing", () => {
          showLoading.value = false;
        });
        player.on("ended", () => {
          showStart.value = false;
          showStop.value = false;
          showEnd.value = true;
        });
        player.on("pause", () => {
          showStop.value = true;
        });
        player.on("loadedmetadata", function () {
          if (detailsData.value.play === 0 && detailsData.value.has_buy === 0 && detailsData.value.start) {
            player.currentTime(detailsData.value.start); // 播放视频

            player.play();
          }
        });
        player.on("timeupdate", () => {
          // 获取当前播放位置（以秒为单位）
          showStop.value = false;
          const currentTime = player.currentTime();

          if (detailsData.value.play === 0 && detailsData.value.has_buy === 0 && detailsData.value.start) {
            if (currentTime < detailsData.value.start) {
              player.currentTime(detailsData.value.start);
            }

            if (currentTime > detailsData.value.end) {
              player.currentTime(detailsData.value.end);
              player.pause();
              showAlert();
            }
          } // console.log("当前播放位置: " + currentTime + " 秒");

        });
      }
    };

    const showAlert = () => {
      Dialog.alert({
        title: "温馨提示",
        confirmButtonText: "加入VIP",
        className: "videoDialog",
        message: "想看更多完整影片，立即注册并验证"
      }).then(() => {
        store.commit("SET_LOGIN_POPUP", {
          show: false,
          type: "VideoDetails"
        });
        router.push("/vip");
      }).catch(() => {// on cancel
      });
    };

    const okBtns3 = () => {
      if (!phone.value) {
        Toast("请输入手机号");
        return;
      }

      const params = {
        phone: phone.value
      };
      modifyPhoneApi(params, "get").then(res => {
        if (res.code === 0) {
          Toast(res.message);
          setTimeout(() => {
            window.location = "/";
          }, 2000);
        }
      });
      showDialog3.value = false;
    };

    const getSimilar = () => {
      const params = {
        video_id: store.state.videoDetails.id
      };
      similarApi(params, "get").then(res => {
        if (res.code === 0) {
          tjList.value = res.data;
        }
      });
    };

    const videoDetails = (id, type) => {
      const params = {
        video_id: id
      };
      getVideoDetailsImg();

      if (store.state.videoDetails.isYl || type === "yl") {
        params.test = 1;
      }

      showLoading.value = true; // options.value.poster = store.state.videoDetails.image;
      // detailsData.value = {};

      commentList.value = [];
      active.value = 0;
      isPay.value = false;
      videoBoxId.value = "videoBox" + id;
      getSimilar();
      videoDetailsApi(params, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          res.data.isLike = isLike.value.includes(res.data.id ? res.data.id + "" : store.state.videoDetails.id + "");
          res.data.isFocus = isFocus.value.includes(res.data.user_id + "");
          detailsData.value = res.data;
          localStorage.setItem("__detailsData", JSON.stringify(res.data));
          nextTick(() => {
            document.getElementById(videoBoxId.value).innerHTML = `<video style="height: 100%;width:100%"  controls
          autoplay src="options.src" type="options.type" webkit-playsinline='true' playsinline='true' id="videoPlayer${id}" class="video-js videoPlayer"></video>`; // setImmediate(() => {

            initVideo(id); // },100)
          });
        } else {
          if (res.code === 500) {}
        }
      });
    };

    videoDetails(store.state.videoDetails.id);

    const videoPlay = (item, type) => {
      videoDetails(item.id, type);
    };

    const tjList = ref([// {
      //   time: "11:00",
      //   title: "纹身女拳交道具插逼2",
      //   pd: "",
      //   num: "104",
      // }
    ]);
    const showAskVideo = ref(false);
    const showAskVideoDetails = ref(false);
    const ask_video_id = ref("12");

    const askVideo = () => {
      const params = {
        video_id: detailsData.value.id || store.state.videoDetails.id
      };

      if (player) {
        player.pause();
      }

      askVideoContinuationApi(params, "get").then(res => {
        if (res.code === 0) {
          if (!res.data.ask_video_id) {
            showAskVideo.value = true;
          } else {
            ask_video_id.value = res.data.ask_video_id;
            showAskVideoDetails.value = true;
          }
        } else {
          showAskVideo.value = true;
        }
      }).catch(err => {
        showAskVideo.value = true;
      });
    };

    const close3 = () => {
      showAskVideoDetails.value = false;
    };

    const close2 = () => {
      showAskVideo.value = false;
    };

    const likeVideo = () => {
      // if (detailsData.value.isLike) {
      //   return;
      // }
      const params = {
        video_id: detailsData.value.id || store.state.videoDetails.id
      };
      likeApi(params, "get").then(res => {
        if (res.code === 0) {
          if (!detailsData.value.isLike) {
            Toast("添加喜欢成功！");
          } else {
            Toast("取消喜欢成功！");
          }

          detailsData.value.isLike = !detailsData.value.isLike;
          store.dispatch("getUserInfo");
        } else {
          Toast("添加失败！");
        }
      });
    };

    const getCommentList = () => {
      const params = {
        video_id: store.state.videoDetails.id,
        page: page.value
      };
      commentApi(params, "get").then(res => {
        if (res.code === 0) {
          commentList.value = commentList.value.concat(res.data.data);
          loading.value = false;

          if (res.data.data.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getCommentList();
    };

    const change = () => {
      if (active.value === 1) {
        commentList.value = [];
        page.value = 1;
        finished.value = false;
        getCommentList();
      }
    }; // getCommentList();


    const message = ref("");

    const commentSave = () => {
      const params = {
        video_id: store.state.videoDetails.id,
        message: message.value
      };
      commentSaveApi(params).then(res => {
        Toast(res.message);
      });
    };

    const showInput = ref(false);

    const inputSend = () => {
      message.value = "";
      showInput.value = true;
    };

    const send = () => {
      showInput.value = false;
      commentSave();
    };

    let userInfo = computed(() => {
      return store.state.userInfo;
    });

    const focusSave = () => {
      // if (detailsData.value.isFocus) {
      //   return;
      // }
      const params = {
        user_id: detailsData.value.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          detailsData.value.isFocus = !detailsData.value.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    };

    const showDetailsPopul = computed(() => {
      // 计算属性初始化加10
      return store.state.showLoginPopup;
    });
    watch(showDetailsPopul, () => {
      if (showDetailsPopul.value.show && showDetailsPopul.value.type === "VideoDetails") {
        videoDetails(store.state.videoDetails.id);
      }
    });

    const close = () => {
      if (player) {
        player.pause();
        player.dispose();
      }

      player = null; // if (hls) {
      //   hls.dispose();
      // }

      options.value.url = "";
      emit("close");

      if (showDetailsPopul.value.type !== "VideoMrtj" && showDetailsPopul.value.type !== "VideoSearch" && showDetailsPopul.value.type !== "HotAuthorInfo" && props.details_type !== 1) {
        store.commit("SET_LOGIN_POPUP", {
          show: false,
          type: "VideoDetails"
        });
      }
    };

    const labelSearch = item => {
      searchText.value = item;
      key.value = Math.random();
      label_name.value = item;

      if (player.dispose) {
        player.pause();
        player.dispose();
      }

      player = null;
      showSearchPopup.value = true;
    };

    const close1 = () => {
      showSearchPopup.value = false;
      videoDetails(store.state.videoDetails.id);
    };

    const checked = ref("");
    const fkMessage = ref("");
    const list = ref([{
      label: "点击播放后中断",
      value: 1
    }, {
      label: "加载失败，无法观看",
      value: 2
    }, {
      label: "其它",
      value: 3
    }]);

    const toggle = item => {
      checked.value = item.value;
    };

    const noPlayOk = () => {
      showDialog.value = false;
      if (checked.value === "") return;
      let params = {
        type: checked.value,
        video_id: detailsData.value.id
      };

      if (checked.value === 3) {
        params.message = fkMessage.value;
      }

      feedbackSaveApi(params).then(res => {
        Toast(res.message);
      });
      console.log(checked);
    };

    const noPlay = () => {
      showDialog.value = true;
      fkMessage.value = "";
      checked.value = "";
    };

    return {
      props,
      close,
      tjList,
      inputSend,
      showInput,
      send,
      detailsData,
      likeVideo,
      message,
      focusSave,
      getMyDate,
      videoPlay,
      bannerList,
      toBannerDetails,
      userInfo,
      commentList,
      isLike,
      isFocus,
      loading,
      onLoad,
      error,
      finished,
      change,
      active,
      options,
      showSearchPopup,
      close1,
      label_name,
      key,
      labelSearch,
      searchText,
      showLoading,
      showDialog,
      list,
      toggle,
      checked,
      fkMessage,
      noPlayOk,
      noPlay,
      showAskVideo,
      showAskVideoDetails,
      ask_video_id,
      askVideo,
      close2,
      close3,
      videoBoxId,
      showDialog3,
      phone,
      okBtns3,
      videoImgs,
      showStart,
      showEnd,
      showStop
    };
  }

};