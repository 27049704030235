import { ref, computed } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
import { Toast } from "vant";
export default {
  name: "ChangeUsername",

  setup(props) {
    const store = useStore();
    let nickname = ref("");
    const {
      modifyNicknameApi
    } = getGlobalProperties().$api;
    let userInfo = computed(() => {
      return store.state.userInfo;
    });

    if (userInfo.value) {
      nickname.value = userInfo.value.nickname;
    }

    const modifyNickname = () => {
      if (!nickname.value) {
        Toast("请输入昵称");
        return;
      }

      modifyNicknameApi({
        nickname: nickname.value
      }, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          store.dispatch("getUserInfo");
        }
      });
    };

    return {
      props,
      nickname,
      modifyNickname
    };
  }

};