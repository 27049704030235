import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-13f4afee"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "changeUsername"
};
const _hoisted_2 = {
  class: "user-nickname-form-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("textarea", {
    placeholder: "请设置昵称(不超过15字)",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.nickname = $event)
  }, null, 512), [[_vModelText, $setup.nickname]])]), _createElementVNode("div", {
    class: "user-public-btn",
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.modifyNickname && $setup.modifyNickname(...args))
  }, "确定")]);
}