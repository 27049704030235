import SwiperCore, { Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss";
import { ref } from "vue";
SwiperCore.use([Scrollbar, Autoplay]);
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup(props) {
    let activeIndex = ref(0);
    let showDetailsPopul = ref(false);
    let searchText = ref("");
    let menuList = ref([]);
    let labelList = ref([]);
    let hotAuthorList = ref([]);
    const {
      homeMenuApi,
      labelApi,
      hotAuthorApi
    } = getGlobalProperties().$api;
    let swipe = ref(null);

    const tabItemClick = (item, index) => {
      activeIndex.value = index;
      swipe.value.swipeTo(index);
    };

    const getMenuList = () => {
      homeMenuApi("", "get").then(res => {
        if (res.code === 0) {
          menuList.value = res.data;
        }
      });
    };

    getMenuList();

    const getLabel = () => {
      labelApi("", "get").then(res => {
        if (res.code === 0) {
          labelList.value = res.data;
        }
      });
    };

    getLabel();

    const getHotAuthor = () => {
      hotAuthorApi("", "get").then(res => {
        if (res.code === 0) {
          hotAuthorList.value = res.data;
        }
      });
    };

    getHotAuthor();

    const labelSearch = item => {
      searchText.value = item.name;
      showDetailsPopul.value = true;
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    return {
      props,
      activeIndex,
      tabItemClick,
      swipe,
      menuList,
      labelList,
      hotAuthorList,
      close,
      showDetailsPopul,
      searchText,
      labelSearch
    };
  }

};