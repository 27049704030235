import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  name: "SetPassword",

  setup(props) {
    const {
      modifyPasswordApi
    } = getGlobalProperties().$api;
    const params = ref({
      password: "",
      re_password: ""
    });

    const editPwd = () => {
      if (!params.value.password || !params.value.re_password) {
        Toast("请输入密码");
        return;
      }

      modifyPasswordApi(params.value).then(res => {
        if (res.code === 0) {
          Toast("修改成功");
        } else {
          Toast(res.message);
        }
      });
    };

    return {
      props,
      editPwd,
      params
    };
  }

};