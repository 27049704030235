import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import VideoItem from "../common/VideoItem.vue";
import { Toast } from "vant";
export default {
  components: {
    VideoItem
  },

  setup(props, {
    emit
  }) {
    const {
      videoLogApi,
      videoDeleteLogApi
    } = getGlobalProperties().$api;
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const showLoading = ref(true);
    const rightText = ref("编辑");
    const videoList = ref([]);
    const isEdit = ref(false);

    const getVideoList = () => {
      videoLogApi({
        page: page.value
      }, "get").then(res => {
        showLoading.value = false;

        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.select = false;
          });

          if (page.value === 1) {
            videoList.value = [];
          }

          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getVideoList();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const close = () => {
      emit("close");
    };

    const edit = () => {
      isEdit.value = !isEdit.value;
    };

    const isAll = ref(false);

    const selectAll = () => {
      videoList.value.forEach(item => {
        if (isAll.value) {
          item.select = false;
        } else {
          item.select = true;
        }
      });
      isAll.value = !isAll.value;
    };

    const videoSelect = item => {
      item.select = !item.select;
    };

    const delVideo = () => {
      let params = {
        video_id: ""
      };
      let ids = [];
      videoList.value.forEach(item => {
        if (item.select) {
          ids.push(item.id);
        }
      });
      params.video_id = ids.join(",");
      videoDeleteLogApi(params, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          loading.value = true;
          page.value = 1;
          getVideoList();
        }
      });
    };

    return {
      props,
      videoList,
      close,
      loading,
      onLoad,
      error,
      finished,
      showLoading,
      getVideoList,
      rightText,
      isEdit,
      edit,
      videoSelect,
      delVideo,
      selectAll
    };
  }

};