import { advertiseDetails } from "@/assets/js/utils.js";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    showNotice: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    // const { countApi } = getGlobalProperties().$api;
    const store = useStore();
    let bannerList = computed(() => {
      // 计算属性初始化加10
      return store.state.bannerList;
    });
    const noticeList = computed(() => {
      return store.state.noticeList;
    });

    const toDetails = item => {
      if (item.link) {
        if (item.link === "vip") {
          store.commit("SET_LOGIN_POPUP", {
            show: true,
            type: "Recharge"
          });
        } else if (item.link === "invitation") {
          store.commit("SET_LOGIN_POPUP", {
            show: true,
            type: "ShareFreeWatch"
          });
        } else {
          // countApi({url: item.link},'get')
          // window.open(item.link);
          advertiseDetails(item.link);
        }
      }
    };

    return {
      props,
      bannerList,
      toDetails,
      noticeList
    };
  }

};