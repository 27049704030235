import { ref, computed } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  name: "AskVideo",

  setup(props, {
    emit
  }) {
    const {
      askVideoSaveApi
    } = getGlobalProperties().$api;
    const store = useStore();
    const isYdguize = ref(false);
    const content = ref("");
    const money = ref("");
    const showAskVideoGz = ref(false);
    const videoDetails = computed(() => {
      // 计算属性初始化加10
      return store.state.videoDetails;
    });
    const isVip = computed(() => {
      return store.state.userInfo.is_vip;
    });

    const ydgzClick = () => {
      isYdguize.value = !isYdguize.value;
    };

    const faBuAskVideo = () => {
      if (!content.value) {
        Toast("请输入求片内容");
        return;
      }

      if (content.value.length < 10) {
        Toast("详细的描述更方便找片，不少于10个字噢~");
        return;
      }

      if (!isYdguize.value) {
        Toast("请确认已阅读棒棒视频发布规则");
        return;
      } // if (isVip.value === 0) {
      //   Toast("仅允许充值会员用户发布求片信息");
      //   return;
      // }


      const params = {
        content: content.value,
        money: money.value,
        video_id: videoDetails.value.id
      };
      askVideoSaveApi(params, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          content.value = "";
          money.value = "";
          emit("close");
        }
      });
    };

    const toGz = () => {
      showAskVideoGz.value = true;
    };

    const close1 = () => {
      showAskVideoGz.value = false;
    };

    const close = () => {
      emit("close");
    };

    return {
      props,
      videoDetails,
      close,
      isYdguize,
      ydgzClick,
      faBuAskVideo,
      content,
      money,
      showAskVideoGz,
      close1,
      toGz
    };
  }

};