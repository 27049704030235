import { ref, computed } from "vue";
import { Toast } from "vant";
import MicroHeadlinesList from "./MicroHeadlinesList.vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import MicroHeadlinesDetails from "./MicroHeadlinesDetails.vue";
import { useStore } from "vuex";
export default {
  name: "AskForVideo",
  components: {
    MicroHeadlinesList,
    MicroHeadlinesDetails
  },

  setup(props) {
    const store = useStore();
    let active = ref(0);
    let newList = ref([]);
    let hotList = ref([]);
    let head_lines_id = ref("");
    let showDetailsPopul = ref(false);
    let finished1 = ref(false);
    let error1 = ref(false);
    let loading1 = ref(false);
    let finished2 = ref(false);
    let error2 = ref(false);
    let loading2 = ref(false);
    let page1 = ref(0);
    let page2 = ref(1);
    const {
      headLineNewApi,
      headLineHotApi
    } = getGlobalProperties().$api;
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });

    const getHeadLineNew = () => {
      headLineNewApi({
        page: page1.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });
          newList.value = newList.value.concat(res.data.rows);
          loading1.value = false;

          if (res.data.rows.length === 0) {
            finished1.value = true;
          }
        }
      });
    };

    const getHeadLineHot = () => {
      headLineHotApi({
        page: page2.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });
          hotList.value = hotList.value.concat(res.data.rows);
          loading2.value = false;

          if (res.data.rows.length === 0) {
            finished2.value = true;
          }
        }
      });
    };

    getHeadLineHot(); // getHeadLineNew();

    const onLoad1 = () => {
      loading1.value = true;
      page1.value++;
      getHeadLineNew();
    };

    const onLoad2 = () => {
      loading2.value = true;
      page2.value++;
      getHeadLineHot();
    };

    const childGetList = () => {
      page1.value = 1;
      page2.value = 1;
      newList.value = [];
      hotList.value = [];
      getHeadLineNew();
      getHeadLineHot();
    };

    const close = () => {
      showDetailsPopul.value = false;
      childGetList();
    };

    const showDetails = item => {
      showDetailsPopul.value = true;
      head_lines_id.value = item.id;
    };

    const stopPropagation = ref(false);

    const change = index => {
      stopPropagation.value = true;
      active.value = index;

      if (index === 0) {
        stopPropagation.value = false;
      }
    };

    const swiper = ref(null);

    const onClickTab = index => {
      swiper.value.swipeTo(index);
    };

    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });
    return {
      props,
      active,
      newList,
      hotList,
      showDetailsPopul,
      close,
      showDetails,
      head_lines_id,
      finished1,
      error1,
      loading1,
      onLoad1,
      finished2,
      error2,
      loading2,
      onLoad2,
      childGetList,
      stopPropagation,
      change,
      onClickTab,
      swiper,
      showBottomBanner
    };
  }

};