import { useStore } from "vuex";
import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  name: "VideoSearch",

  setup(props) {
    const store = useStore();
    const {
      hotLabelApi
    } = getGlobalProperties().$api;
    const hotLabel = ref([]);
    let searchLabels = ref([]);
    let label_id = ref("");
    let key = ref("");

    const getHotLabel = () => {
      hotLabelApi("", "get").then(res => {
        if (res.code === 0) {
          hotLabel.value = res.data;
        }
      });
    };

    getHotLabel();
    let searchText = ref("");
    let searchTexts = ref("");
    const showDetailsPopul = ref(false);

    if (localStorage.getItem("searchLabels")) {
      searchLabels.value = JSON.parse(localStorage.getItem("searchLabels"));
    }

    const search = () => {
      showDetailsPopul.value = true;
      key.value = Math.random();
      label_id.value = "";
      searchText.value = searchTexts.value;

      if (searchText.value) {
        if (!searchLabels.value.includes(searchText.value)) {
          searchLabels.value.push(searchText.value);
          localStorage.setItem("searchLabels", JSON.stringify(searchLabels.value));
        }
      }
    };

    const clear = () => {
      localStorage.removeItem("searchLabels");
      searchLabels.value = [];
    };

    const labelSearch = item => {
      searchText.value = item.name;
      label_id.value = item.id;
      key.value = Math.random();
      showDetailsPopul.value = true;
    };

    const close = () => {
      store.commit("SET_LOGIN_POPUP", {
        show: false,
        type: "VideoSearch"
      });
      searchTexts.value = "";
    };

    const close1 = () => {
      showDetailsPopul.value = false;
    };

    return {
      props,
      close,
      search,
      searchText,
      searchTexts,
      hotLabel,
      labelSearch,
      showDetailsPopul,
      close1,
      searchLabels,
      clear,
      label_id,
      key
    };
  }

};