import { ref, computed } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  name: "HotAuthorInfo",
  props: {
    searchText: {
      type: String,
      default: ""
    },
    id: {
      type: Number
    }
  },

  setup(props, {
    emit
  }) {
    const {
      autoerVideoApi,
      focusSaveApi
    } = getGlobalProperties().$api;
    const store = useStore();
    let page = ref(1);
    let finished = ref(false);
    let error = ref(false);
    let loading = ref(false);
    let videoList = ref([]);
    let authorInfo = ref({});
    let detailsTitle = ref("");
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });
    const searchText = computed(() => {
      // 计算属性初始化加10
      return ref(props.searchText);
    });
    const user_id = computed(() => {
      // 计算属性初始化加10
      return ref(props.id);
    });

    const getAuthorInfo = () => {
      detailsTitle.value = searchText.value.value;
      autoerVideoApi({
        user_id: user_id.value.value,
        page: page.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.isFocus = isFocus.value.includes(user_id.value.value + "");
          authorInfo.value = res.data;
          videoList.value = videoList.value.concat(res.data.rows.rows);
          loading.value = false;

          if (!res.data.rows || res.data.rows.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getAuthorInfo();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getAuthorInfo();
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const focusSave = () => {
      // if (authorInfo.value.isFocus) {
      //   return;
      // }
      const params = {
        user_id: user_id.value.value
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          authorInfo.value.isFocus = !authorInfo.value.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    };

    const close = () => {
      emit("close");
    };

    return {
      props,
      detailsTitle,
      close,
      videoList,
      videoPlay,
      loading,
      onLoad,
      error,
      finished,
      authorInfo,
      focusSave
    };
  }

};