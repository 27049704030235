import { ref } from "vue";
export default {
  name: "NoData",
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },

  setup(props) {
    const item = ref(props.itemData);
    return {
      props,
      item
    };
  }

};