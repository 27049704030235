import { ref } from "vue";
export default {
  name: "TagManagement",

  setup(props, {
    emit
  }) {
    const close = () => {
      emit("close");
    };

    const labelValue = ref("");
    const showDialog = ref(false);
    const labelLists = ref([{
      label: "标签"
    }]);

    const add = () => {
      labelValue.value = "";
      showDialog.value = true;
    };

    const okBtns = () => {
      showDialog.value = false;
    };

    const edit = item => {
      labelValue.value = item.label;
      showDialog.value = true;
    };

    return {
      props,
      close,
      add,
      showDialog,
      okBtns,
      labelLists,
      edit,
      labelValue
    };
  }

};