import { ref, computed } from "vue";
import { useStore } from "vuex";
import SelectVideo from "./SelectVideo.vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  props: ["id"],
  components: {
    SelectVideo
  },

  setup(props) {
    const store = useStore();
    let type = ref(1);
    let key = ref(1);
    let showDetailsPopul = ref(false);
    const {
      askVideoSubmitApi
    } = getGlobalProperties().$api;

    const close = () => {
      showDetailsPopul.value = false;
    };

    const recommendVideoList = computed(() => {
      return store.state.recommendVideoList;
    });

    const showDetails = i => {
      type.value = i;
      key.value = Math.random();
      showDetailsPopul.value = true;
    };

    const submit = () => {
      if (recommendVideoList.value.length === 0) {
        Toast("请选择视频");
        return;
      }

      if (recommendVideoList.value.length > 10) {
        Toast("最多推荐十个视频噢！");
        return;
      }

      let video_id = [];
      let params = {
        video_id: "",
        ask_video_id: props.id
      };
      recommendVideoList.value.forEach(item => {
        video_id.push(item.id);
      });
      params.video_id = video_id.join(",");
      askVideoSubmitApi(params, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          store.commit("SET_RECOMMEND_VIDEO_LIST", []);
          close();
        }
      });
    };

    const deleteF = item => {
      let arr = recommendVideoList.value.filter(elem => elem.id !== item.id);
      console.log(arr);
      store.commit("SET_RECOMMEND_VIDEO_LIST", arr);
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    return {
      props,
      showDetailsPopul,
      showDetails,
      close,
      recommendVideoList,
      type,
      submit,
      key,
      videoPlay,
      deleteF
    };
  }

};