import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import AskForVideoList from "./AskForVideoList.vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import AskForVideoDetails from "./AskForVideoDetails.vue";
export default {
  name: "AskForVideo",
  components: {
    AskForVideoList,
    AskForVideoDetails
  },

  setup(props) {
    const store = useStore();
    let active = ref(0);
    let newList = ref([]);
    let hotList = ref([]);
    let recommendList = ref([]);
    let showDetailsPopul = ref(false);
    let ask_video_id = ref("");
    let key = ref(1);
    let finished1 = ref(false);
    let error1 = ref(false);
    let loading1 = ref(false);
    let finished2 = ref(false);
    let error2 = ref(false);
    let loading2 = ref(false);
    let page1 = ref(0);
    let page2 = ref(1);
    const showLoading = ref(false);
    const askSearchData = ref({
      type: "",
      money: "",
      confirm_status: "",
      page: 1
    });
    const showSearch = ref(false);
    const stopPropagation = ref(true);
    const {
      askVideoNewApi,
      askVideoHotApi,
      askVideoRecommendApi,
      askVideoSearchApi
    } = getGlobalProperties().$api;
    let isWant = computed(() => {
      return store.state.userInfo.want.split(",");
    });

    const getAskVideoNew = () => {
      askVideoNewApi({
        page: page1.value
      }, "get").then(res => {
        showLoading.value = false;

        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isWant = isWant.value.includes(item.id + "");
          });
          newList.value = newList.value.concat(res.data.rows);
          loading1.value = false;

          if (res.data.rows.length === 0) {
            finished1.value = true;
          }
        }
      });
    };

    const getAskVideoHot = () => {
      askVideoHotApi({
        page: page2.value
      }, "get").then(res => {
        showLoading.value = false;
        console.log(res);

        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isWant = isWant.value.includes(item.id + "");
          });
          hotList.value = hotList.value.concat(res.data.rows);
          loading2.value = false;

          if (res.data.rows.length === 0) {
            finished2.value = true;
          }
        }
      });
    };

    const getAskVideoRecommend = () => {
      askVideoRecommendApi("", "get").then(res => {
        if (res.code === 0) {
          recommendList.value = res.data;
        }
      });
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    const showDetails = item => {
      showDetailsPopul.value = true;
      key.value = Math.random();
      ask_video_id.value = item.id;
    };

    const toAutorDetails = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    getAskVideoHot(); // getAskVideoNew();

    getAskVideoRecommend();

    const change = index => {
      stopPropagation.value = true;
      active.value = index;

      if (index === 2) {
        stopPropagation.value = false;
      }
    };

    const showSearchFun = () => {
      showSearch.value = true;
      askSearchData.value.page = 1;
    };

    const askSearchFun = (num, type) => {
      askSearchData.value[type] = num;
    };

    const isSearch = ref(false);

    const askSearch = () => {
      isSearch.value = true;
      showSearch.value = false;
      showLoading.value = true; // loading1.value = true;
      // loading2.value = true;

      if (active.value === 0) {
        if (askSearchData.value.page === 1) {
          newList.value = [];
        }

        if (askSearchData.value.money === "" && askSearchData.value.confirm_status === "") {
          isSearch.value = false;
          newList.value = []; // loading1.value = true;

          page1.value = 1;
          getAskVideoNew();
          return;
        }
      } else {
        if (askSearchData.value.page === 1) {
          hotList.value = [];
        }

        if (askSearchData.value.type === "" && askSearchData.value.money === "" && askSearchData.value.confirm_status === "") {
          isSearch.value = false;
          hotList.value = []; // loading2.value = true;

          page2.value = 1;
          getAskVideoHot();
          return;
        }
      }

      let params = {
        money: askSearchData.value.money,
        confirm_status: askSearchData.value.confirm_status,
        page: askSearchData.value.page
      };

      if (active.value === 1) {
        params.type = askSearchData.value.type;
      }

      askVideoSearchApi(params, "get").then(res => {
        showLoading.value = false;

        if (res.code === 0) {
          loading1.value = false;
          loading2.value = false;
          console.log(res);

          if (active.value === 0) {
            res.data.rows.forEach(item => {
              item.isWant = isWant.value.includes(item.id + "");
            });
            newList.value = newList.value.concat(res.data.rows);

            if (res.data.rows.length === 0) {
              finished1.value = true;
            }
          } else {
            res.data.rows.forEach(item => {
              item.isWant = isWant.value.includes(item.id + "");
            });
            hotList.value = hotList.value.concat(res.data.rows);

            if (res.data.rows.length === 0) {
              finished2.value = true;
            }
          }
        }
      });
    };

    const reset = () => {
      askSearchData.value = {
        type: "",
        money: "",
        confirm_status: "",
        page: 1
      };
    };

    const swiper = ref(null);

    const onClickTab = index => {
      swiper.value.swipeTo(index);
    };

    const onLoad1 = () => {
      if (!isSearch.value) {
        loading1.value = true;
        page1.value++;
        getAskVideoNew();
      } else {
        askSearchData.value.page++;
        askSearch("fy");
      }
    };

    const onLoad2 = () => {
      if (!isSearch.value) {
        loading2.value = true;
        page2.value++;
        getAskVideoHot();
      } else {
        askSearchData.value.page++;
        askSearch("fy");
      }
    };

    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });
    return {
      props,
      active,
      newList,
      hotList,
      recommendList,
      showDetailsPopul,
      close,
      showDetails,
      ask_video_id,
      toAutorDetails,
      key,
      finished1,
      error1,
      loading1,
      onLoad1,
      finished2,
      error2,
      loading2,
      onLoad2,
      stopPropagation,
      change,
      showSearch,
      showSearchFun,
      askSearchFun,
      askSearchData,
      askSearch,
      reset,
      onClickTab,
      swiper,
      showLoading,
      showBottomBanner
    };
  }

};