import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0508c87a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "audltComics"
};
const _hoisted_2 = {
  class: "cont"
};
const _hoisted_3 = {
  class: "top_nav"
};
const _hoisted_4 = {
  class: "crmh_cont"
};
const _hoisted_5 = {
  class: "list"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0,
  class: "item"
};
const _hoisted_8 = {
  class: "crmh_cont"
};
const _hoisted_9 = {
  class: "list"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  key: 0,
  class: "item"
};
const _hoisted_12 = {
  class: "details_page details_page_app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_comics_item = _resolveComponent("comics-item");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_swipe_item = _resolveComponent("van-swipe-item");

  const _component_van_swipe = _resolveComponent("van-swipe");

  const _component_comics_item_details = _resolveComponent("comics-item-details");

  const _component_van_popup = _resolveComponent("van-popup");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: _normalizeClass(["top_nav_item", $setup.topNavIndex === 0 ? 'active' : '']),
    onClick: _cache[0] || (_cache[0] = $event => $setup.topNavClick(0))
  }, " 最新排序 ", 2), _createElementVNode("div", {
    class: _normalizeClass(["top_nav_item", $setup.topNavIndex === 1 ? 'active' : '']),
    onClick: _cache[1] || (_cache[1] = $event => $setup.topNavClick(1))
  }, " 最热排序 ", 2)]), _createVNode(_component_van_swipe, {
    class: "my-swipe",
    onChange: $setup.change,
    loop: false,
    ref: "swiper",
    "show-indicators": false
  }, {
    default: _withCtx(() => [_createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_list, {
        loading: $setup.loading,
        "onUpdate:loading": _cache[2] || (_cache[2] = $event => $setup.loading = $event),
        error: $setup.error,
        "onUpdate:error": _cache[3] || (_cache[3] = $event => $setup.error = $event),
        finished: $setup.finished,
        "error-text": "请求失败，点击重新加载",
        "finished-text": "-我也是有底线的-",
        "loading-text": "正在获取数据...",
        onLoad: $setup.onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.comicsNewList, item => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: item.id,
            onClick: $event => $setup.toDetails(item)
          }, [_createVNode(_component_comics_item, {
            data: item
          }, null, 8, ["data"])], 8, _hoisted_6);
        }), 128)), $setup.comicsNewList.length % 3 === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["loading", "error", "finished", "onLoad"])])]),
      _: 1
    }), _createVNode(_component_van_swipe_item, null, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createVNode(_component_van_list, {
        loading: $setup.loading,
        "onUpdate:loading": _cache[4] || (_cache[4] = $event => $setup.loading = $event),
        error: $setup.error,
        "onUpdate:error": _cache[5] || (_cache[5] = $event => $setup.error = $event),
        finished: $setup.finished,
        "error-text": "请求失败，点击重新加载",
        "finished-text": "-我也是有底线的-",
        "loading-text": "正在获取数据...",
        onLoad: $setup.onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.comicsHotList, item => {
          return _openBlock(), _createElementBlock("div", {
            class: "item",
            key: item.id,
            onClick: $event => $setup.toDetails(item)
          }, [_createVNode(_component_comics_item, {
            data: item
          }, null, 8, ["data"])], 8, _hoisted_10);
        }), 128)), $setup.comicsHotList.length % 3 === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_11)) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["loading", "error", "finished", "onLoad"])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["onChange"])]), _createVNode(_component_van_popup, {
    show: $setup.showDetailsPopul,
    "onUpdate:show": _cache[6] || (_cache[6] = $event => $setup.showDetailsPopul = $event),
    class: "popup_coentent",
    overlay: false,
    position: "right",
    teleport: "#app"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_12, [(_openBlock(), _createBlock(_component_comics_item_details, {
      comics_id: $setup.comics_id,
      onClose: $setup.close,
      key: $setup.key
    }, null, 8, ["comics_id", "onClose"]))])]),
    _: 1
  }, 8, ["show"])]);
}