import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast, Dialog } from "vant";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
import BountyList from "./BountyList.vue";
import RecommendVideo from "./RecommendVideo.vue";
export default {
  components: {
    BountyList,
    RecommendVideo
  },
  props: ["id", "isMy"],

  setup(props) {
    const store = useStore();
    const {
      askVideoDetailApi,
      askVideoRewardApi,
      askVideoCommentListApi,
      focusSaveApi,
      askVideoCommentLikeApi,
      askVideoConfirmApi
    } = getGlobalProperties().$api;
    let detailData = ref("");
    let showDialog = ref(false);
    let moneyValue = ref("");
    let time = ref(0);
    let showDetailsPopul = ref(false);
    let popupType = ref(1);
    let ask_video_id = ref("");
    let key = ref(1);
    let rxqzList = ref([]);
    let finished = ref(false);
    let error = ref(false);
    let loading = ref(false);
    let page = ref(0);
    let total = ref(0);
    let showVideoDetailsPopul = ref(false);
    let videoId = ref("");
    let type = ref(1);
    const showLoading = ref(false);
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });
    let change_id = computed(() => {
      console.log(props.isMy);
      return store.state.userInfo.change_id;
    });

    const toAutorDetails = item => {
      if (item.isWant === "null") return; // store.commit("SET_LOGIN_POPUP", { show: true, type: "HotAuthorInfo" });

      store.commit("SET_VIDEO_DETAILS", item);
      type.value = 2;
      videoId.value = item.id;
      showVideoDetailsPopul.value = true;
    };

    const getAskVideoDetail = () => {
      showLoading.value = true;
      askVideoDetailApi({
        ask_video_id: props.id
      }, "get").then(res => {
        showLoading.value = false;

        if (res.code === 0) {
          time.value = res.data.end * 1000;
          detailData.value = res.data;
          ask_video_id.value = res.data.id;
        }
      });
    };

    getAskVideoDetail();

    const getAskVideoCommentList = () => {
      askVideoCommentListApi({
        ask_video_id: props.id,
        page: page.value
      }, "get").then(res => {
        if (res.code === 0) {
          total.value = res.data.total;
          res.data.rows.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });
          rxqzList.value = rxqzList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    }; // getAskVideoCommentList();


    const onLoad = () => {
      loading.value = true;
      page.value++;
      getAskVideoCommentList();
    };

    const showDsPopup = () => {
      moneyValue.value = "";
      showDialog.value = true;
    };

    const okBtns = () => {
      if (moneyValue.value === "") {
        Toast("打赏金额不能为空");
        return;
      }

      const params = {
        ask_video_id: props.id,
        money: moneyValue.value
      };
      askVideoRewardApi(params).then(res => {
        Toast(res.message);

        if (res.code === 0) {
          getAskVideoDetail();
        }
      });
      showDialog.value = false;
    };

    const toBountyList = type => {
      popupType.value = type;
      key.value = Math.random();
      store.commit("SET_RECOMMEND_VIDEO_LIST", []);
      showDetailsPopul.value = true;
    };

    const close = () => {
      showDetailsPopul.value = false;
      getAskVideoDetail();
      page.value = 1;
      rxqzList.value = [];
      getAskVideoCommentList();
    };

    const focusSave = item => {
      // if (item.isFocus) {
      //   return;
      // }
      const params = {
        user_id: item.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          item.isFocus = !item.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    };

    const getAskVideoCommentLike = item => {
      askVideoCommentLikeApi({
        ask_video_id: item.id
      }, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          item.like_num++;
        }
      });
    };

    const cainaF = id => {
      Dialog.confirm({
        theme: "round-button",
        className: "myconfirm",
        title: "采纳推荐者视频",
        message: "赏金将会分给该推荐者"
      }).then(() => {
        askVideoConfirmApi({
          comment_id: id
        }, "get").then(res => {
          Toast(res.message);

          if (res.code === 0) {
            getAskVideoDetail();
          }
        });
      }).catch(() => {// on cancel
      });
    };

    const videoPlay = item => {
      // store.commit("SET_LOGIN_POPUP", { show: true, type: "VideoDetails" });
      store.commit("SET_VIDEO_DETAILS", item);
      type.value = 1;
      videoId.value = item.id;
      showVideoDetailsPopul.value = true;
    };

    const close1 = () => {
      showVideoDetailsPopul.value = false;
    };

    return {
      props,
      toAutorDetails,
      detailData,
      getMyDate,
      okBtns,
      showDialog,
      moneyValue,
      showDsPopup,
      time,
      close1,
      showDetailsPopul,
      showVideoDetailsPopul,
      videoId,
      type,
      close,
      toBountyList,
      popupType,
      ask_video_id,
      key,
      rxqzList,
      total,
      focusSave,
      videoPlay,
      finished,
      error,
      loading,
      onLoad,
      getAskVideoCommentLike,
      change_id,
      cainaF,
      showLoading
    };
  }

};