import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-bd6539f2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "advertise"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "img_box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_my_image = _resolveComponent("my-image");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bannerList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "banner_item",
      key: index,
      onClick: $event => $setup.toDetails(item)
    }, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_my_image, {
      url: item.url
    }, null, 8, ["url"])])], 8, _hoisted_2);
  }), 128))]);
}