import { ref, computed } from "vue";
import QrcodeVue from "qrcode.vue";
import Clipboard from "clipboard";
import { Toast } from "vant";
import { useStore } from "vuex";
export default {
  name: "ShareFreeWatch",
  components: {
    QrcodeVue
  },

  setup(props, {
    emit
  }) {
    const showDetailsPopul = ref(false);
    const store = useStore();

    const close = type => {
      if (type === 1) {
        emit("close");

        if (store.state.showLoginPopup.type === "ShareFreeWatch") {
          store.commit("SET_LOGIN_POPUP", {
            show: false,
            type: "ShareFreeWatch"
          });
        }
      } else if (type === 2) {
        showDetailsPopul.value = false;
      }
    };

    const copy = () => {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", () => {
        Toast("复制成功，快去分享吧！"); // 释放内存

        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        Toast("该浏览器不支持自动复制"); // 释放内存

        clipboard.destroy();
      });
    };

    const downImg = () => {
      Toast("请自行截图分享二维码！");
    };

    const toDetails = () => {
      showDetailsPopul.value = true;
    };

    const userInfo = computed(() => {
      return store.state.userInfo;
    });
    const qrUrl = computed(() => {
      return store.state.userInfo.share_url;
    });
    const qrUrl2 = computed(() => {
      return location.origin + "/home" + store.state.userInfo.share_url.slice(store.state.userInfo.share_url.indexOf("?invite_code"));
    });
    return {
      props,
      qrUrl,
      qrUrl2,
      close,
      copy,
      downImg,
      toDetails,
      showDetailsPopul,
      userInfo
    };
  }

};