import { ref } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
export default {
  name: "VideoMrtj",

  setup(props) {
    const store = useStore();
    const {
      dailyConcentrationApi
    } = getGlobalProperties().$api;
    let page = ref(0);
    const finished = ref(false);
    let videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    let showDetailsPopul = ref(false);
    let videoId = ref("");
    let type = ref(1);

    const getVideoList = () => {
      dailyConcentrationApi({
        page: page.value
      }, "get").then(res => {
        if (res.code === 0) {
          // videoList.value = res.data.rows;
          res.data.rows.forEach((item, index) => {
            item.titleTime = getMyDate(item.created, 1);

            if (index !== 0 && getMyDate(item.created, 1) === getMyDate(res.data.rows[index - 1].created, 1)) {
              item.titleTime = "";
            }
          });
          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    }; // getVideoList();


    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const close = () => {
      store.commit("SET_LOGIN_POPUP", {
        show: false,
        type: "VideoMrtj"
      });
    };

    const close1 = () => {
      showDetailsPopul.value = false;
    };

    const videoPlay = item => {
      store.commit("SET_VIDEO_DETAILS", item);
      videoId.value = item.id;
      type.value = 1;
      showDetailsPopul.value = true;
    };

    const toAutorDetails = item => {
      store.commit("SET_VIDEO_DETAILS", item);
      videoId.value = item.id;
      type.value = 2;
      showDetailsPopul.value = true;
    };

    const showShare = item => {
      store.commit("SET_SHOW_SHARE_POPUP", {
        show: true,
        videoDetails: item
      });
    };

    return {
      props,
      close,
      videoList,
      loading,
      onLoad,
      error,
      videoPlay,
      finished,
      getMyDate,
      showDetailsPopul,
      close1,
      videoId,
      type,
      toAutorDetails,
      showShare
    };
  }

};