import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-ccaf0f04"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "height": "100px"
  }
};
const _hoisted_2 = {
  style: {
    "margin-top": "10px"
  }
};
const _hoisted_3 = {
  key: 0,
  class: "video_list"
};
const _hoisted_4 = {
  key: 1,
  class: "no-data"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading = _resolveComponent("loading");

  const _component_SwipeComponent = _resolveComponent("SwipeComponent");

  const _component_advertise_swipe = _resolveComponent("advertise-swipe");

  const _component_video_item = _resolveComponent("video-item");

  const _component_no_data = _resolveComponent("no-data");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");

  return _openBlock(), _createBlock(_component_van_pull_refresh, {
    modelValue: $setup.refreshing,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.refreshing = $event),
    "head-height": 80,
    onRefresh: $setup.onRefresh
  }, {
    loading: _withCtx(() => [_createVNode(_component_loading)]),
    default: _withCtx(() => [$setup.refreshing ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), _createVNode(_component_van_list, {
      loading: $setup.loading,
      "onUpdate:loading": _cache[0] || (_cache[0] = $event => $setup.loading = $event),
      error: $setup.error,
      "onUpdate:error": _cache[1] || (_cache[1] = $event => $setup.error = $event),
      finished: $setup.finished,
      "error-text": "请求失败，点击重新加载",
      "finished-text": "-我也是有底线的-",
      "loading-text": "正在获取数据...",
      onLoad: $setup.onLoad
    }, {
      default: _withCtx(() => [_createVNode(_component_SwipeComponent), _createElementVNode("div", _hoisted_2, [_createVNode(_component_advertise_swipe)]), $setup.videoList && $setup.videoList.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.videoList, item => {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "video_item_box"
        }, [(_openBlock(), _createBlock(_component_video_item, {
          key: item.id,
          dataItem: item
        }, null, 8, ["dataItem"]))]);
      }), 128))])) : !$setup.loading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_no_data, {
        color: "#ffffff"
      })])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["loading", "error", "finished", "onLoad"])]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]);
}