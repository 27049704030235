/*
 * @Author: ian2020 ian2020008@gmail.com
 * @Date: 2022-08-19 18:59:38
 * @LastEditors: ian2020 ian2020008@gmail.com
 * @LastEditTime: 2022-09-01 22:46:46
 * @FilePath: /sex/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from "vue-router";
// console.log(import.meta.env.BASE_URL)
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/tabs/IndexView.vue"),
      redirect: "/home",
      children: [
        {
          path: "/home",
          name: "home",
          component: () => import("../views/tabs/home/HomeView.vue"),
        },
        {
          path: "/original",
          name: "original",
          component: () => import("../views/tabs/original/OriginalView.vue"),
        },
        {
          path: "/community",
          name: "community",
          component: () => import("../views/tabs/community/CommunityView.vue"),
        },
        {
          path: "/vip",
          name: "vip",
          component: () => import("../views/tabs/vip/vip.vue"),
        },
        {
          path: "/member",
          name: "member",
          component: () => import("../views/tabs/member/MemberView.vue"),
        },
      ],
    },
    {
      path: "/DialogIsAdvertis",
      name: "DialogIsAdvertis",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../components/DialogIsAdvertis.vue"),
    },
    {
      path: "/iframe",
      name: "iframe",
      component: () => import("../views/tabs/iframe/index.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/user/login.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("../views/user/register.vue"),
    },
    {
      path: "/retrievePwd",
      name: "retrievePwd",
      component: () => import("../views/user/retrievePwd.vue"),
    },
    {
      path: "/personalInformation",
      name: "personalInformation",
      component: () => import("../views/user/personalInformation.vue"),
    },
  ],
});

export default router;
