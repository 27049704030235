import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import ComicsItem from "./ComicsItem.vue";
import ComicsItemDetails from "./ComicsItemDetails.vue";
export default {
  components: {
    ComicsItem,
    ComicsItemDetails
  },

  setup(props, {
    emit
  }) {
    const {
      comicsNewApi,
      comicsHotApi
    } = getGlobalProperties().$api;
    const topNavIndex = ref(0);
    const swiper = ref(null);
    const showDetailsPopul = ref(false);
    const key = ref("");
    const comicsNewList = ref([]);
    const comicsHotList = ref([]);
    const page = ref(0);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const page1 = ref(1);
    const finished1 = ref(false);
    const error1 = ref(false);
    const loading1 = ref(false);
    const comics_id = ref("");

    const getComicsNew = () => {
      comicsNewApi({
        page: page.value
      }, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          comicsNewList.value = comicsNewList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const getComicsHot = () => {
      comicsHotApi({
        page: page1.value
      }, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          comicsHotList.value = comicsHotList.value.concat(res.data.rows);
          loading1.value = false;

          if (res.data.rows.length === 0) {
            finished1.value = true;
          }
        }
      });
    }; // getComicsNew();


    const onLoad = () => {
      loading.value = true;
      page.value++;
      getComicsNew();
    };

    getComicsHot();

    const onLoad1 = () => {
      loading1.value = true;
      page1.value++;
      getComicsHot();
    };

    const close = type => {
      showDetailsPopul.value = false;

      if (type !== 1) {
        emit("close");
      }
    };

    const change = index => {
      topNavIndex.value = index;
    };

    const topNavClick = index => {
      swiper.value.swipeTo(index);
    };

    const toDetails = item => {
      console.log(item);
      comics_id.value = item.id;
      key.value = Math.random();
      showDetailsPopul.value = true;
    };

    return {
      props,
      close,
      change,
      swiper,
      topNavIndex,
      topNavClick,
      showDetailsPopul,
      toDetails,
      key,
      comicsNewList,
      comicsHotList,
      finished,
      error,
      loading,
      onLoad,
      finished1,
      error1,
      loading1,
      onLoad1,
      comics_id
    };
  }

};