import { ref, computed } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
import AskForVideoList from "@/views/tabs/community/components/AskForVideoList.vue";
import AskForVideoDetails from "@/views/tabs/community/components/AskForVideoDetails.vue";
import MicroHeadlinesList from "@/views/tabs/community/components/MicroHeadlinesList.vue";
import MicroHeadlinesDetails from "@/views/tabs/community/components/MicroHeadlinesDetails.vue";
export default {
  name: "MyMicropost",
  components: {
    AskForVideoList,
    AskForVideoDetails,
    MicroHeadlinesList,
    MicroHeadlinesDetails
  },

  setup(props, {
    emit
  }) {
    const store = useStore();
    const {
      userAskVideoApi,
      userAskVideoLikeApi,
      userAskVideoSubmitApi,
      userHeadLinesApi,
      userHeadLineLikesApi
    } = getGlobalProperties().$api;
    let showDetailsPopul = ref(false);
    let myQpList = ref([]);
    let myLikeList = ref([]);
    let myTjList = ref([]);
    let wfbdList = ref([]);
    let wxhdList = ref([]);
    let page1 = ref(0);
    let finished1 = ref(false);
    let error1 = ref(false);
    let loading1 = ref(false);
    let page2 = ref(0);
    let finished2 = ref(false);
    let error2 = ref(false);
    let loading2 = ref(false);
    let page3 = ref(0);
    let finished3 = ref(false);
    let error3 = ref(false);
    let loading3 = ref(false);
    let page4 = ref(0);
    let finished4 = ref(false);
    let error4 = ref(false);
    let loading4 = ref(false);
    let page5 = ref(0);
    let finished5 = ref(false);
    let error5 = ref(false);
    let loading5 = ref(false);
    let isWant = computed(() => {
      return store.state.userInfo.want.split(",");
    });
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });

    const close = type => {
      if (type === 1) {
        emit("close");
      } else {
        showDetailsPopul.value = false;
      }
    };

    const getUserAskVideo = () => {
      userAskVideoApi({
        page: page1.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isWant = "null";
          });
          myQpList.value = myQpList.value.concat(res.data.rows);
          loading1.value = false;

          if (res.data.rows.length === 0) {
            finished1.value = true;
          }
        }
      });
    };

    const getUserAskVideoLike = () => {
      userAskVideoLikeApi({
        page: page2.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isWant = isWant.value.includes((item.ask_video_id || item.id) + "");
          });
          myLikeList.value = myLikeList.value.concat(res.data.rows);
          loading2.value = false;

          if (res.data.rows.length === 0) {
            finished2.value = true;
          }
        }
      });
    };

    const getUserAskVideoSubmit = () => {
      userAskVideoSubmitApi({
        page: page3.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isWant = isWant.value.includes((item.ask_video_id || item.id) + "");
          });
          myTjList.value = myTjList.value.concat(res.data.rows);
          loading3.value = false;

          if (res.data.rows.length === 0) {
            finished3.value = true;
          }
        }
      });
    };

    const getUserHeadLines = () => {
      userHeadLinesApi({
        page: page4.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isFocus = "null";
          });
          wfbdList.value = wfbdList.value.concat(res.data.rows);
          loading4.value = false;

          if (res.data.rows.length === 0) {
            finished4.value = true;
          }
        }
      });
    };

    const getUserHeadLineLikes = () => {
      userHeadLineLikesApi({
        page: page5.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });
          wxhdList.value = wxhdList.value.concat(res.data.rows);
          loading5.value = false;

          if (res.data.rows.length === 0) {
            finished5.value = true;
          }
        }
      });
    }; // getUserAskVideo();
    // getUserAskVideoLike();
    // getUserAskVideoSubmit();
    // getUserHeadLines();
    // getUserHeadLineLikes();


    const onLoad1 = () => {
      loading1.value = true;
      page1.value++;
      getUserAskVideo();
    };

    const onLoad2 = () => {
      loading2.value = true;
      page2.value++;
      getUserAskVideoLike();
    };

    const onLoad3 = () => {
      loading3.value = true;
      page3.value++;
      getUserAskVideoSubmit();
    };

    const onLoad4 = () => {
      loading4.value = true;
      page4.value++;
      getUserHeadLines();
    };

    const onLoad5 = () => {
      loading5.value = true;
      page5.value++;
      getUserHeadLineLikes();
    };

    let key = ref("");
    let ask_video_id = ref("");
    let head_lines_id = ref("");
    let type = ref("");
    let isMy = ref("");

    const showDetails = (item, ty, my) => {
      key.value = Math.random();
      isMy.value = my || "";
      type.value = ty;

      if (ty === "qp") {
        ask_video_id.value = item.ask_video_id || item.id;
      } else if (ty === "tt") {
        head_lines_id.value = item.head_lines_id || item.id;
      }

      showDetailsPopul.value = true;
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    return {
      props,
      close,
      videoPlay,
      showDetailsPopul,
      myQpList,
      myLikeList,
      myTjList,
      wfbdList,
      wxhdList,
      onLoad1,
      error1,
      finished1,
      loading1,
      onLoad2,
      error2,
      finished2,
      loading2,
      onLoad3,
      error3,
      finished3,
      loading3,
      onLoad4,
      error4,
      finished4,
      loading4,
      onLoad5,
      error5,
      finished5,
      loading5,
      key,
      ask_video_id,
      showDetails,
      head_lines_id,
      type,
      isMy
    };
  }

};