import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  name: "OnlineService",

  setup(props) {
    const showDetailsPopul = ref(false);
    const detailsTitle = ref("");
    const content = ref("");
    const list = ref([{
      title: "如何获得赏银？",
      content: "在【我的】-【钱包】-【充值赏银】选择需要充值赏银的数量，在弹出的支付页面选择支付方式后支付后即可获得赏银。"
    }, {
      title: "如何推广？",
      content: "在「我的」-「分享推广」中,「保存二维码图片」,或「复制链接」,分享给好友。新用户下载安装APP后,即可绑定推送关系,用户的全部消费,您都可以获得返利,最高比例可返 30%。"
    }, {
      title: "怎样才算邀请成功？",
      content: "发送推广链接给其他新用户，用户第一次安装并打开app后算 邀请成功，或在个人中心输入您的推广码。"
    }, {
      title: "为什么推广链接别人打不开？",
      content: "请勿使用微信或者QQ等第三方内置浏览器打开，因为包含 色情内容导致被屏蔽，推荐使用自带浏览器或UC等浏览器打开。"
    }]);

    const toDetails = item => {
      detailsTitle.value = item.titlex;
      content.value = item.content;
      showDetailsPopul.value = true;
    };

    const kefuUrl = ref("");

    const toKefu = () => {
      // window.open(kefuUrl.value);
      location.href = kefuUrl.value;
    };

    const {
      customerApi
    } = getGlobalProperties().$api;

    const getCustomer = () => {
      customerApi("", "get").then(res => {
        if (res.code === 0) {
          kefuUrl.value = res.data.customer_service;
        }
      });
    };

    getCustomer();

    const close = () => {
      showDetailsPopul.value = false;
    };

    return {
      props,
      showDetailsPopul,
      toDetails,
      close,
      detailsTitle,
      content,
      list,
      toKefu
    };
  }

};