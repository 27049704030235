import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
export default {
  props: ["id"],

  setup(props, {
    emit
  }) {
    const store = useStore();
    const {
      headLineDetailApi,
      focusSaveApi,
      headLineCommentLikeApi
    } = getGlobalProperties().$api;
    let detailData = ref("");
    let showInput = ref(false);
    let message = ref("");
    const showLoading = ref(false);

    const toAutorDetails = item => {
      if (item.isFocus === "null") return;
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });
    const fileUrl = computed(() => {
      return store.state.userInfo.top_image;
    });

    const getHeadLineDetail = () => {
      showLoading.value = true;
      headLineDetailApi({
        head_lines_id: props.id
      }, "get").then(res => {
        showLoading.value = false;

        if (res.code === 0) {
          res.data.isFocus = isFocus.value.includes(res.data.user_id + "");

          if (res.data.user_id === store.state.userInfo.uid) {
            res.data.isFocus = "null";
          }

          detailData.value = res.data;
        }
      });
    };

    getHeadLineDetail();

    const inputSend = () => {
      message.value = "";
      showInput.value = true;
    };

    const send = () => {
      showInput.value = false;
    };

    const focusSave = item => {
      // if (item.isFocus) {
      //   return;
      // }
      const params = {
        user_id: item.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          detailData.value.isFocus = !detailData.value.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    };

    const getHeadLineCommentLike = item => {
      headLineCommentLikeApi({
        head_lines_id: item.id
      }, "get").then(res => {
        Toast(res.message);

        if (res.code === 0) {
          item.like_num++; // emit("childGetList");
        }
      });
    };

    return {
      props,
      toAutorDetails,
      detailData,
      getMyDate,
      fileUrl,
      showInput,
      send,
      message,
      inputSend,
      focusSave,
      getHeadLineCommentLike,
      showLoading
    };
  }

};