import Vue from "vue";

// 引入的骨架屏组件
import skeletonHome from "./views/skeleton/index.vue";

export default new Vue({
    components: {
        skeletonHome,
    },
    template: `
        <div>
            <skeletonHome id="skeleton-home" style="display:none"/>
        </div>
    `,
});
