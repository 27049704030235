import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
import router from "@/router";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  name: "PersonalInformation",

  setup(props) {
    const store = useStore();
    const showDialog = ref(false);
    const pupupValue = ref("");
    const popupText = ref("");
    const detailsTitle = ref("");
    const detailsView = ref("");
    const showDetailsPopul = ref(false);
    const showDialog2 = ref(false);
    const showDialog3 = ref(false);
    const email = ref("");
    const phone = ref("");
    const userInfo = computed(() => {
      return store.state.userInfo;
    });
    const fileUrl = computed(() => {
      return store.state.userInfo.top_image || "123.png";
    });
    const {
      inviteSaveApi,
      modifyTopImageApi,
      modifyEmailApi,
      modifyPhoneApi
    } = getGlobalProperties().$api;

    const showDialogFun = type => {
      pupupValue.value = "";

      if (type === "dhm") {
        popupText.value = "兑换码";
      } else if (type === "yqm") {
        popupText.value = "邀请码";
      }

      showDialog.value = true;
    };

    const okBtns = () => {
      if (pupupValue.value === "") {
        Toast(popupText.value + "不能为空");
      }

      if (popupText.value === "兑换码") {
        inviteSaveApi({
          invite_code: pupupValue.value
        }).then(res => {
          Toast(res.message);
        });
      } else {
        inviteSaveApi({
          invite_code: pupupValue.value
        }).then(res => {
          Toast(res.message);
        });
      }

      showDialog.value = false;
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    const back = () => {
      history.back();
    };

    const toDetails = type => {
      if (type === "nc") {
        detailsTitle.value = "个人管理";
        detailsView.value = "ChangeUsername";
      } else if (type === "mm") {
        detailsTitle.value = "设置密码";
        detailsView.value = "SetPassword";
      }

      showDetailsPopul.value = true;
    };

    const toLogin = () => {
      router.push("/login");
    };

    const afterRead = file => {
      console.log(file);

      if (file.file.type !== "image/jpeg" && file.file.type !== "image/png") {
        Toast("上传格式为png,jpeg");
        return;
      }

      if (file.file.size / 1024 / 1024 > 2) {
        Toast("上传头像图片大小不能超过 2MB!");
        return;
      }

      let formdate = new FormData();
      formdate.append("file", file.file);
      modifyTopImageApi(formdate).then(res => {
        Toast(res.message);

        if (res.code === 0) {
          store.dispatch("getUserInfo");
        }
      });
    };

    const bindEmail = () => {
      showDialog2.value = true;
    };

    const bindPhone = () => {
      showDialog3.value = true;
    };

    const okBtns2 = () => {
      if (email.value === "") {
        Toast("邮箱账号不能为空");
        return;
      }

      const toast = Toast.loading({
        message: "更新中...",
        forbidClick: true,
        duration: 0
      });
      modifyEmailApi({
        email: email.value
      }).then(res => {
        Toast(res.message);
        toast.clear();

        if (res.code === 0) {
          showDialog2.value = false;
          store.dispatch("getUserInfo");
        }
      });
    };

    const okBtns3 = () => {
      if (!phone.value) {
        Toast("请输入手机号");
        return;
      }

      const params = {
        phone: phone.value
      };
      modifyPhoneApi(params, "get").then(res => {
        if (res.code === 0) {
          Toast(res.message);
          setTimeout(() => {
            window.location = "/";
          }, 2000);
        }
      });
      showDialog3.value = false;
    };

    return {
      props,
      showDialog,
      showDialogFun,
      okBtns,
      pupupValue,
      popupText,
      showDetailsPopul,
      close,
      detailsTitle,
      toDetails,
      detailsView,
      toLogin,
      userInfo,
      afterRead,
      fileUrl,
      bindEmail,
      showDialog2,
      email,
      okBtns2,
      bindPhone,
      showDialog3,
      phone,
      okBtns3,
      back
    };
  }

};