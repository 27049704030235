import { ref, computed, watch } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import VideoItem from "../home/tabs/common/VideoItem.vue";
import { useStore } from "vuex";
export default {
  components: {
    VideoItem
  },

  setup(props) {
    let page = ref(1);
    const finished = ref(false);
    let videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    const store = useStore();
    let showLoding = ref(true);
    const bannerList = ref([]);
    const menuId = ref('');
    const active = ref(0);
    const {
      makerIndexApi,
      makerTypeApi
    } = getGlobalProperties().$api;
    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });
    let navs = ref([]);

    const getOrginalList = () => {
      makerIndexApi('', "get").then(res => {
        showLoding.value = false;
        videoList.value = [];

        if (res.code === 0) {
          navs.value = res.data.menu;
          bannerList.value = res.data.banner.rows;
          menuId.value = navs.value[0].id; // videoList.value = res.data.video

          getVideoList();
        }
      });
    };

    getOrginalList();
    watch(active, () => {
      menuId.value = navs.value[active.value].id;
      page.value = 1;
      getVideoList();
    });

    const getVideoList = () => {
      makerTypeApi({
        page: page.value,
        type: menuId.value
      }, "get").then(res => {
        if (res.code === 0) {
          if (page.value === 1) {
            videoList.value = [];
          }

          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const toDetails = item => {
      if (item.link) {
        if (item.link === "vip") {
          store.commit("SET_LOGIN_POPUP", {
            show: true,
            type: "Recharge"
          });
        } else if (item.link === "invitation") {
          store.commit("SET_LOGIN_POPUP", {
            show: true,
            type: "ShareFreeWatch"
          });
        } else {
          window.open(item.link);
        }
      }
    };

    const handleShowStack = path => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: path
      });
    };

    return {
      props,
      videoList,
      loading,
      onLoad,
      error,
      finished,
      showLoding,
      handleShowStack,
      navs,
      bannerList,
      showBottomBanner,
      active
    };
  }

};