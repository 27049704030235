import { ref, computed } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
export default {
  props: ["comics_id", "comics_status"],

  setup(props, {
    emit
  }) {
    const {
      comicsDirectoryApi
    } = getGlobalProperties().$api;
    const directoryList = ref([]);
    const store = useStore();
    const isSort = ref(true);
    directoryList.value = store.state.directoryList;
    const isVip = computed(() => {
      return store.state.userInfo.is_vip;
    });

    const getComicsDirectory = () => {
      comicsDirectoryApi({
        comics_id: props.comics_id
      }, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          directoryList.value = res.data;
          store.commit("SET_DIRECTORY_LIST", res.data);
          emit("getComicsDirectory", res.data);
        }
      });
    };

    getComicsDirectory();

    const initDirectoryList = () => {
      directoryList.value = directoryList.value.sort((a, b) => {
        if (isSort.value) {
          return a.sort - b.sort;
        }

        {
          return b.sort - a.sort;
        }
      });
    };

    const sortFun = () => {
      isSort.value = !isSort.value;
      initDirectoryList();
    };

    const startReading = item => {
      if (item.is_free === 0 && item.money === 0 && isVip.value === 0) {
        store.commit("SETSHOE_KT_VIP", true);
      } else {
        emit("startReading", item);
      }
    };

    return {
      props,
      startReading,
      directoryList,
      isSort,
      sortFun
    };
  }

};