import { ref, computed } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import ComicsItem from "./ComicsItem.vue";
import ComicsItemDetails from "./ComicsItemDetails.vue";
import ComicsContent from "./ComicsContents.vue";
import ComicsContentsDetails from "./ComicsContentsDetails.vue";
import { useStore } from "vuex";
export default {
  components: {
    ComicsItem,
    ComicsItemDetails,
    ComicsContent,
    ComicsContentsDetails
  },
  props: ["comics_id"],

  setup(props, {
    emit
  }) {
    const {
      comicsDetailsApi,
      comicsSimilarApi,
      comicsLikeApi
    } = getGlobalProperties().$api;
    const store = useStore();
    const topNavIndex = ref(0);
    const swiper = ref(null);
    const showDetailsPopul = ref(false);
    const showDetailsPopul1 = ref(false);
    const key = ref("");
    const key1 = ref("");
    const detailsData = ref({});
    const similarList = ref([]);
    const comics_directory_id = ref("");
    const directoryList = ref([]);
    const comics_id = ref("");
    let isComicsLike = computed(() => {
      return store.state.userInfo.comics_like.split(",");
    });

    const getComicsDetails = () => {
      comicsDetailsApi({
        comics_id: props.comics_id
      }, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          res.data.isComicsLike = isComicsLike.value.includes(res.data.id + "");
          detailsData.value = res.data;
        }
      });
    };

    getComicsDetails();

    const getComicsSimilar = () => {
      comicsSimilarApi({
        comics_id: props.comics_id
      }, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          similarList.value = res.data;
        }
      });
    };

    getComicsSimilar();

    const comicsLike = () => {
      // if (detailsData.value.isComicsLike) return;
      comicsLikeApi({
        comics_id: props.comics_id
      }, "get").then(res => {
        console.log(res);

        if (res.code === 0) {
          detailsData.value.isComicsLike = !detailsData.value.isComicsLike;
          store.dispatch("getUserInfo");
        }
      });
    };

    const getComicsDirectory = list => {
      directoryList.value = list;
    };

    const close = () => {
      if (showDetailsPopul.value) {
        showDetailsPopul.value = false;
      } else {
        emit("close", 1);
      }
    };

    const close1 = () => {
      showDetailsPopul1.value = false;
    };

    const change = index => {
      topNavIndex.value = index;
    };

    const topNavClick = index => {
      swiper.value.swipeTo(index);
    };

    const toDetails = item => {
      console.log(item);
      comics_id.value = item.id;
      key.value = Math.random();
      showDetailsPopul.value = true;
    };

    const toFenxian = () => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "ShareFreeWatch"
      });
    };

    const isVip = computed(() => {
      return store.state.userInfo.is_vip;
    });

    const startReading = item => {
      console.log(item);

      if (item.is_free === 0 && item.money === 0 && isVip.value === 0) {
        store.commit("SETSHOE_KT_VIP", true);
      } else {
        comics_directory_id.value = item.id;
        key1.value = Math.random();
        showDetailsPopul1.value = true;
      }
    };

    return {
      props,
      close,
      close1,
      change,
      swiper,
      topNavIndex,
      topNavClick,
      showDetailsPopul,
      showDetailsPopul1,
      toDetails,
      key,
      key1,
      toFenxian,
      startReading,
      detailsData,
      similarList,
      comics_directory_id,
      comicsLike,
      getComicsDirectory,
      directoryList,
      comics_id
    };
  }

};