import { ref } from "vue";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
export default {
  name: "ExpensesRecord",

  setup(props) {
    const {
      payLogApi
    } = getGlobalProperties().$api;
    const payLogList = ref([]);
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const showPopover = ref(false);
    let showDetailsPopul = ref(false);
    let typeText = ref("全部明细");
    let type = ref("");

    const getPayLogList = () => {
      const params = {
        page: page.value,
        type: type.value
      };
      payLogApi(params, "get").then(res => {
        if (res.code === 0) {
          payLogList.value = payLogList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const search = () => {
      showPopover.value = true;
    }; // 通过 actions 属性来定义菜单选项


    const actions = [{
      text: "全部明细",
      value: ""
    }, {
      text: "收入",
      value: 1
    }, {
      text: "支出",
      value: 2
    }];

    const onSelect = action => {
      typeText.value = action.text;
      type.value = action.value;
      page.value = 1;
      payLogList.value = [];
      getPayLogList();
    };

    getPayLogList();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getPayLogList();
    };

    const toDetails = () => {
      showDetailsPopul.value = true;
    };

    const close = () => {
      showDetailsPopul.value = false;
    };

    return {
      props,
      payLogList,
      loading,
      onLoad,
      error,
      finished,
      getMyDate,
      actions,
      onSelect,
      showPopover,
      typeText,
      search,
      showDetailsPopul,
      close,
      toDetails
    };
  }

};