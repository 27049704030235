import { getGlobalProperties } from "@/assets/js/utils.js";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
export default {
  props: {
    searchText: {
      type: String,
      default: ""
    },
    menu_id: {
      type: Number,
      default: ""
    }
  },

  setup(props, {
    emit
  }) {
    const store = useStore();
    const searchText = computed(() => {
      // 计算属性初始化加10
      return ref(props.searchText);
    });
    const menu_id = computed(() => {
      // 计算属性初始化加10
      return ref(props.menu_id);
    });
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });
    const {
      newApi,
      focusSaveApi
    } = getGlobalProperties().$api;
    let page = ref(1);
    const finished = ref(false);
    let videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    let detailsTitle = ref("");

    const getVideoList = () => {
      detailsTitle = searchText.value.value;
      newApi({
        page: page.value,
        menu_id: menu_id.value.value
      }, "get").then(res => {
        if (res.code === 0) {
          res.data.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });
          videoList.value = res.data;
          loading.value = false;

          if (res.data.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getVideoList();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const focusSave = item => {
      // if (item.isFocus) {
      //   return;
      // }
      const params = {
        user_id: item.author_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          item.isFocus = !item.isFocus;
          store.dispatch("getUserInfo");
        }
      });
    };

    const videoPlay = item => {
      store.commit("SET_VIDEO_DETAILS", item);
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
    };

    const close = () => {
      emit("close");
    };

    let userInfo = computed(() => {
      return store.state.userInfo;
    });
    return {
      props,
      videoList,
      videoPlay,
      focusSave,
      userInfo,
      loading,
      onLoad,
      error,
      finished,
      detailsTitle,
      close
    };
  }

};