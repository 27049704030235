import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1,
  class: "startImg"
};
const _hoisted_3 = {
  key: 0,
  class: "circle"
};
const _hoisted_4 = ["src"];
import request from "@/assets/js/request.js";
import { getBase64, getGlobalProperties, advertiseDetails } from "@/assets/js/utils.js";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',

  setup(__props) {
    const store = useStore();
    const {
      startApi,
      lindLoginApi
    } = getGlobalProperties().$api;
    const showView = ref(false);
    const imgbase = ref("");

    function getParameterByName(name) {
      name = name.replace(/[[]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(window.location.search);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    if (getParameterByName("uid")) {
      lindLoginApi({
        username: getParameterByName("uid")
      }, "get").then(res => {
        if (res.code === 0) {
          store.commit("SET_USER_INFO", res.data);
          store.commit("SET_HG_OAUTH_ID", "login");
        }
      });
    }

    const getBaseUrl = () => {
      try {
        console.log(11111);
        request.get("/api/v1/index/ping").then(res => {
          if (res.code === 0) {
            res.data.urls = getBase64(res.data.urls).split(","); // res.data.urls =
            //   res.data.urls[Math.floor(Math.random() * res.data.urls.length)];

            imgbase.value = res.data.image_url;
            store.commit("SET_BASE_URL", res.data);
          }

          getStart(); // showView.value = true;
        }).catch(() => {
          console.log(2222);
          showView.value = true;
        });
      } catch (error) {
        console.log(3333);
        showView.value = true;
      }
    };

    getBaseUrl();

    const getTokenFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("token");
    };

    const getInfo = () => {
      const obj = {
        focus_user: "",
        like_path: "",
        nickname: "",
        want: "",
        comics_like: "",
        token: getTokenFromURL()
      };
      store.commit("SET_USER_INFO", obj);
      store.dispatch("getUserInfo");
    };

    if (getTokenFromURL()) {
      getInfo();
    }

    const imgLink = ref("");
    const startImg = ref("");
    const currentRate = ref(0);
    const text = ref(5);

    const getStart = () => {
      startApi("", "get").then(res => {
        console.log(res);

        if (res.code === 0 && res.data && res.data.second_url) {
          startImg.value = res.data.second_url;
          imgLink.value = res.data.second_link;
          let timer = setInterval(() => {
            text.value--;
            currentRate.value = 100 / text.value;

            if (text.value <= 0) {
              clearInterval(timer);
              showView.value = true;
            }
          }, 1000);
        } else {
          showView.value = true;
        }
      }).catch(err => {
        showView.value = true;
      });
    };

    const toLink = () => {
      if (imgLink.value) {
        // countApi({ url: imgLink.value }, "get");
        // window.open(imgLink.value);
        advertiseDetails(imgLink.value);
      }
    };

    window.document.documentElement.setAttribute("data-theme", "dark");

    window.onload = function () {
      var lastTouchEnd = 0;
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("touchend", function (event) {
        var now = new Date().getTime();

        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }

        lastTouchEnd = now;
      }, false);
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
      document.addEventListener("dblclick", function (event) {
        event.preventDefault();
      });
      document.body.addEventListener("touchmove", function (e) {
        console.log(e);

        if (e.target.className === "van-tabbar-item") {
          e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)
        }
      }, {
        passive: false
      });
    };

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_van_circle = _resolveComponent("van-circle");

      return showView.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [startImg.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_van_circle, {
        "current-rate": currentRate.value,
        "onUpdate:current-rate": _cache[0] || (_cache[0] = $event => currentRate.value = $event),
        rate: 100,
        speed: 20,
        size: "50px",
        "layer-color": "#B8A9B0",
        color: "#ffc0cb",
        "stroke-width": 100,
        text: text.value + ' S'
      }, null, 8, ["current-rate", "text"])])) : _createCommentVNode("", true), startImg.value ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        onClick: toLink,
        src: imgbase.value + startImg.value,
        alt: ""
      }, null, 8, _hoisted_4)) : _createCommentVNode("", true)]));
    };
  }

};