import { useStore } from "vuex";
import QrcodeVue from "qrcode.vue";
import Clipboard from "clipboard";
import { Toast } from "vant";
import { ref, computed, watch } from "vue";
export default {
  name: "SharePopup",
  components: {
    QrcodeVue
  },

  setup() {
    const store = useStore();
    let showDetailsPopul = ref(false);
    let videoDetails = ref({});
    const qrUrl = computed(() => {
      return store.state.userInfo.share_url;
    });
    const showSharePopup = computed(() => {
      return store.state.showSharePopup;
    });
    watch(showSharePopup, () => {
      if (showSharePopup.value.show) {
        showDetailsPopul.value = true;
        videoDetails.value = showSharePopup.value.videoDetails;
      } else {
        showDetailsPopul.value = false;
      }
    });

    const copy = () => {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", () => {
        Toast("复制成功，快去分享吧！"); // 释放内存

        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        Toast("该浏览器不支持自动复制"); // 释放内存

        clipboard.destroy();
      });
    };

    const downImg = () => {
      Toast("请自行在本页面截图保存");
    };

    return {
      showDetailsPopul,
      qrUrl,
      copy,
      downImg,
      videoDetails
    };
  }

};