import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-d35f4360"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "top"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "iframe"
};
const _hoisted_5 = ["src"];
import { computed, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getGlobalProperties } from "@/assets/js/utils.js";
export default {
  __name: 'index',

  setup(__props) {
    const {
      withdrawApi,
      cpWithdrawApi,
      qyWithdrawApi
    } = getGlobalProperties().$api;
    const route = useRoute();
    const router = useRouter();
    const title = computed(() => {
      return route.query.title;
    });
    const url = computed(() => {
      return route.query.url;
    });

    const back = () => {
      router.replace("/vip");
    };

    onBeforeUnmount(() => {
      const code = sessionStorage.getItem("__code");
      const gameId = sessionStorage.getItem("__gameId");

      if (code === "zr") {
        withdrawApi({
          company_id: gameId
        });
      }

      if (code === "cp") {
        cpWithdrawApi({
          company_id: gameId
        });
      }

      if (code === 'dz') {
        qyWithdrawApi({
          company_id: gameId
        });
      }
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        onClick: back
      }), _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(title)), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("iframe", {
        src: _unref(url),
        frameborder: "0"
      }, null, 8, _hoisted_5)])]);
    };
  }

};