export default {
  name: "AskVideoGz",

  setup(props, {
    emit
  }) {
    const close = () => {
      emit("close");
    };

    return {
      props,
      close
    };
  }

};