import { ref } from "vue";
import { Toast } from "vant";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { useStore } from "vuex";
export default {
  props: ["id"],

  setup(props) {
    const store = useStore();
    const {
      askVideoRewardListApi,
      askVideoRewardApi
    } = getGlobalProperties().$api;
    let showDialog = ref(false);
    let moneyValue = ref("");
    let rewardList = ref([]);
    let finished = ref(false);
    let error = ref(false);
    let loading = ref(false);
    let page = ref(0);
    let total_money = ref("");

    const showDsPopup = () => {
      moneyValue.value = "";
      showDialog.value = true;
    };

    const getAskVideoRewardList = () => {
      askVideoRewardListApi({
        ask_video_id: props.id,
        page: page.value
      }, "get").then(res => {
        if (res.code === 0) {
          total_money.value = res.data.total_money;
          rewardList.value = rewardList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    }; // getAskVideoRewardList();


    const onLoad = () => {
      loading.value = true;
      page.value++;
      getAskVideoRewardList();
    };

    const okBtns = () => {
      if (moneyValue.value === "") {
        Toast("打赏金额不能为空");
        return;
      }

      const params = {
        ask_video_id: props.id,
        money: moneyValue.value
      };
      askVideoRewardApi(params).then(res => {
        Toast(res.message);

        if (res.code === 0) {
          page.value = 1;
          rewardList.value = [];
          getAskVideoRewardList();
        }
      });
      showDialog.value = false;
    };

    const toAutorDetails = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    return {
      props,
      showDialog,
      moneyValue,
      showDsPopup,
      okBtns,
      rewardList,
      finished,
      error,
      loading,
      onLoad,
      total_money,
      toAutorDetails
    };
  }

};